import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

function AuthorizedEntityInfoSkeleton() {
    return (
        <div>
            <div className="px-3.5">
                <div className="mb-10">
                    <Skeleton className="md:w-2/12" />
                </div>
                <Card className="mt-3">
                    <CardContent>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                <Typography variant="body2">
                                    <Skeleton className="md:w-2/12" />
                                </Typography>
                                <Typography>
                                    <Skeleton className="md:w-3/12" />
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body2">
                                    <Skeleton className="md:w-2/12" />
                                </Typography>
                                <Typography>
                                    <Skeleton className="md:w-4/12" />
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body2">
                                    <Skeleton className="md:w-2/12" />
                                </Typography>
                                <Typography>
                                    <Skeleton className="md:w-3/12" />
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body2">
                                    <Skeleton className="md:w-2/12" />
                                </Typography>
                                <Typography>
                                    <Skeleton className="md:w-4/12" />
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="body2">
                                    <Skeleton className="md:w-2/12" />
                                </Typography>
                                <Typography>
                                    <Skeleton className="md:w-4/12" />
                                </Typography>
                            </div>
                        </div>

                        <div className="py-4 space-y-4">
                            {Array(5)
                                .fill(0)
                                .map(() => (
                                    <div>
                                        <Typography variant="body2">
                                            <Skeleton className="md:w-3/12" />
                                        </Typography>
                                        <div className="flex items-center justify-between">
                                            <Skeleton className="md:w-8/12" />
                                            <div>
                                                <Skeleton className="w-8 h-8" />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default AuthorizedEntityInfoSkeleton;
