import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { http } from 'Utils/Http/Http';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Yup } from '@aseel/common-utils';
import { Btn, BtnBase, CheckboxController, setProgress, InputController } from '@aseel/common-ui';
import { handleError } from '@aseel/http';
import { Box } from '@mui/system';

const styles = {
    modalHeader: {
        fontSize: '1.5rem',
    },
    textBoxPadding: {
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    controllButtonStyle: {
        width: '7rem',
        marginLeft: '1rem',
    },
};

const schema = Yup.object().shape({
    name_en: Yup.string().required(),
    name_ar: Yup.string().required(),
    is_active: Yup.bool(),
});

const CREATE_UPDATE_FUND_BTN = 'CREATE_UPDATE_FUND_BTN';

// Component goes here
const CreateOrEditFundModal = ({
    isFundCreateEditModalOpen,
    isEdit,
    selectedFundData,
    availableFundsQuery,
    setIsFundCreateEditModalOpen,
}) => {
    const { control, handleSubmit, reset, setError } = useForm({
        defaultValues: {
            is_active: false,
        },
        resolver: yupResolver(schema),
    });
    const { t } = useTranslation();

    // Handlers
    const closeCreateEditModal = () => {
        reset({ is_active: false });
        setIsFundCreateEditModalOpen(false);
        availableFundsQuery.refetch();
    };

    // Final Form Submission
    const handleFormSubmission = async (formData) => {
        setProgress(CREATE_UPDATE_FUND_BTN, true);

        try {
            await http.request({
                method: isEdit ? 'PUT' : 'POST',
                url: isEdit ? `/property-types/${selectedFundData.id}` : '/property-types',
                data: formData,
            });
            closeCreateEditModal();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CREATE_UPDATE_FUND_BTN, false);
    };

    useEffect(() => {
        if (isFundCreateEditModalOpen === false) {
            return;
        }
        if (isEdit) {
            reset({ ...selectedFundData });
        } else {
            reset({ is_active: false });
        }
    }, [isEdit, isFundCreateEditModalOpen]);

    return (
        <Dialog open={isFundCreateEditModalOpen} onClose={closeCreateEditModal}>
            <DialogContent>
                <Typography variant="h6" component="h1" className="mb-3">
                    {isEdit ? t('fundTypes.editFundType') : t('fundTypes.createFundType')}
                </Typography>

                <form onSubmit={handleSubmit(handleFormSubmission)} className="space-y-3">
                    <InputController
                        control={control}
                        name="name_en"
                        label={t('fundTypes.fundNameEn')}
                    />
                    <InputController
                        control={control}
                        name="name_ar"
                        label={t('fundTypes.fundNameAr')}
                    />
                    <CheckboxController control={control} name="is_active" label={t('fundTypes.isActive')} />

                    <DialogActions className="flex justify-end">
                        <BtnBase variant="text" onClick={closeCreateEditModal} fullWidth={false}>
                            {t('fundTypes.close')}
                        </BtnBase>
                        <Btn type="submit" name={CREATE_UPDATE_FUND_BTN} fullWidth={false}>
                            {isEdit ? t('fundTypes.update') : t('fundTypes.create')}
                        </Btn>
                    </DialogActions>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default CreateOrEditFundModal;
