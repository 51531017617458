import React from 'react';
import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

// Component goes here
export default function InvestorInfoSkeleton() {
    // Handlers

    return (
        <div className="grid grid-cols-2 gap-5">
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>

            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    <Skeleton className="w-1/2" />
                </Typography>
                <Typography variant="body1">
                    <Skeleton />
                </Typography>
            </div>
        </div>
    );
}
