import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Entry from './Entry';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, responsiveFontSizes, ThemeProvider, StyledEngineProvider } from '@mui/material';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import ModalContainer from 'react-modal-promise';
import { setI18nInstance } from '@aseel/common-utils';
import i18n from 'Utils/I18n';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDayJs from '@mui/lab/AdapterDayjs';
import { mergeEditorConfigs } from '@aseel/common-ui/src';

setI18nInstance(i18n);

mergeEditorConfigs({
    simpleUpload: {
        uploadUrl: `${process.env.REACT_APP_BASE_URL}/api/admin/editor-images`,
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    },
});

let theme = createTheme({
    palette: {
        primary: {
            main: '#343549',
        },
        secondary: {
            main: '#506f9d',
        },
        green: {
            main: '#4CAF50',
            contrastText: '#fff',
        },
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
            '2xl': 1536,
        },
    },
});

theme = responsiveFontSizes(theme);

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayJs}>
                        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                            <BrowserRouter basename="/">
                                <Entry />
                            </BrowserRouter>
                        </GoogleReCaptchaProvider>
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </QueryClientProvider>
        <ToastContainer />
        <ModalContainer />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
