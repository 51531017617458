export const ENQUIRIES = 'ENQUIRIES';
export const ENQUIRY_REPLIES = 'ENQUIRY_REPLIES';
export const INVESTORS = 'INVESTORS';
export const INVESTOR_INVESTMENTS = 'INVESTOR_INVESTMENTS';
export const INVESTOR_BALANCE = 'INVESTOR_BALANCE';
export const INVESTOR_AML = 'INVESTOR_AML';
export const INVESTOR_TRANSACTIONS = 'INVESTOR_TRANSACTIONS';
export const AVAILABLE_FUND_TYPES = 'AVAILABLE_FUND_TYPES';
export const PAGE_PRIVACY_POLICY = 'PAGE_PRIVACY_POLICY';
export const PAGE_CODE_OF_CONDUCTS = 'PAGE_CODE_OF_CONDUCTS';
export const PAGE_SHARIAA_COMPLIANCE = 'PAGE_SHARIAA_COMPLIANCE';
export const PAGE_TERMS_AND_CONDITIONS = 'PAGE_TERMS_AND_CONDITIONS';
export const PAGE_ABOUT_US = 'PAGE_ABOUT_US';
export const PAGE_COUNCIL_OF_MEMBERS = 'PAGE_COUNCIL_OF_MEMBERS';
export const PAGE_DISCLOSURE_POLICY = 'PAGE_DISCLOSURE_POLICY';
export const EOD_STATEMENTS_LIST = 'EOD_STATEMENTS_LIST';
export const FUNDS = 'FUNDS';
export const EOD_STATEMENTS_TRANSACTION_LIST = 'EOD_STATEMENTS_TRANSACTION_LIST';
export const BANK_ACCOUNTS = 'BANK_ACCOUNTS';
export const CITIES_STATIC_DATA = 'CITIES_STATIC_DATA';
export const FUND_TYPES_STATIC_DATA = 'FUND_TYPES_STATIC_DATA';
export const FUND_REVIEWS = 'FUND_REVIEWS';
export const FUND_INFORMATIONS = 'FUND_FUND_INFORMATIONS';
export const INVESTMENT_TYPE_STATIC_DATA = 'INVEST_MENT_TYPE_STATIC_DATA';
export const AUTHORIZED_ENTITIES = 'AUTHORIZED_ENTITIES';
export const AUTHORIZED_ENTITY_USERS = 'AUTHORIZED_ENTITY_USERS';
export const FAQS = 'FAQS';
export const CONTACT_US_REASONS = 'CONTACT_US_REASONS';
export const FUND_DISCUSSIONS = 'FUND_DISCUSSIONS';
export const FUND_IBAN_INVESTORS = 'FUND_IBAN_INVESTORS';
export const FUND_IBAN_WALLETS = 'FUND_IBAN_WALLETS';
export const FUND_IBAN_TRANSACTIONS = 'FUND_IBAN_TRANSACTIONS';
export const SYSTEM_LOGS = 'SYSTEM_LOGS';
export const WORKER_LOGS = 'WORKER_LOGS';
export const DASHBOARD_FUNDS = 'DASHBOARD_FUNDS';
export const DASHBOARD_OVERVIEW = 'DASHBOARD_OVERVIEW';
export const FUND_REPORTS = 'FUND_REPORTS';
export const FUND_PAYOUTS = 'FUND_PAYOUTS';
export const FUND_PAYOUT_INVESTORS = 'FUND_PAYOUT_INVESTORS';
export const FUND_VALUATIONS = 'FUND_VALUATIONS';
export const ANB_BALANCES = 'ANB_BALANCES';
export const ANB_EOD_TRANSACTIONS = 'ANB_EOD_TRANSACTIONS';
export const ANB_TRANSACTIONS = 'ANB_TRANSACTIONS';
export const ANB_EXPORT_REPORTS = 'ANB_EXPORT_REPORTS';
export const PROFESSIONAL_UPGRADE_REQUESTS = 'PROFESSIONAL_UPGRADE_REQUESTS';
