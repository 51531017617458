import React from 'react';
import { Card, CardContent, Typography, Skeleton } from '@mui/material';

export default function ShowProfessionalRequestSkeleton() {
    return (
        <div className="space-y-5 px-2.5 sm:px-0">
            <Typography variant="h4">
                <Skeleton className="w-1/4" />
            </Typography>
            <Card>
                <div className="p-5">
                    <Skeleton className="sm:w-1/12 w-1/4" />
                    <Skeleton className="sm:w-2/12 w-1/2" />
                </div>
                <div className="px-5 pb-5 space-y-3.5">
                    <div>
                        <Skeleton className="sm:w-1/12 w-1/4" />
                        <Skeleton className="sm:w-8/12 w-1/2" />
                    </div>
                    <div>
                        <Skeleton className="sm:w-1/12 w-1/4" />
                        <Skeleton className="sm:w-8/12 w-1/2" />
                    </div>
                </div>
            </Card>
            <Card className="px-3 py-5">
                <Typography variant="h3">
                    <Skeleton className="w-1/4" />
                </Typography>
                <CardContent className="py-2">
                    <Typography variant="h2">
                        <Skeleton />
                    </Typography>
                    <div className="flex justify-end">
                        <Skeleton className="w-1/12 h-10" />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
