import { Box } from '@mui/system';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import React, { useState } from 'react';
import { i18n, url } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import TableSkeleton from 'Skeletons/TableSkeleton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ANB_EOD_TRANSACTIONS } from 'Constants/QueriesKeys';
import { DataGrid, IconBtnBase, Menu } from '@aseel/common-ui';
import { exportingReportFormats } from '@aseel/common-components';
import { Card, IconButton, MenuItem, Paper } from '@mui/material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const styles = {
    tableSize: {
        height: 650,
        width: '100%',
    },
};

const formatsOfExporitngReport = (row) => {
    const path = `api/admin/anb/eod-statements/${row.row.id}/transactions/export`;

    return [
        {
            url: url(path, { extension: exportingReportFormats.xlsx }),
            label: i18n.t('exportReports.xlsx'),
        },
        {
            url: url(path, { extension: exportingReportFormats.csv }),
            label: i18n.t('exportReports.csv'),
        },
    ];
};

export default function EODStatementsTab() {
    const { path } = useRouteMatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const eodStatementsQuery = useQuery([ANB_EOD_TRANSACTIONS, { page }], () =>
        http.get(`/anb/eod-statements`, { params: { page } }).then(({ data }) => data),
    );

    const columns = [
        {
            field: 'date',
            headerName: t('anb.date'),
            minWidth: 100,
            editable: false,
        },
        {
            field: 'bank_name',
            headerName: t('anb.bank'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'account_number',
            headerName: t('anb.accountNumber'),
            minWidth: 160,
            editable: false,
        },
        {
            field: 'currency',
            headerName: t('anb.currency'),
            minWidth: 100,
            editable: false,
            renderCell: (row) => row.value ?? '---',
        },
        {
            field: 'sequence_number',
            headerName: t('anb.sequenceNumber'),
            minWidth: 160,
            editable: false,
            renderCell: (row) => row.value ?? '---',
        },
        {
            field: 'opening_balance',
            headerName: t('anb.openingBalance'),
            minWidth: 160,
            editable: false,
        },
        {
            field: 'closing_balance',
            headerName: t('anb.closingBalance'),
            minWidth: 160,
            editable: false,
        },
        {
            field: 'action',
            headerName: t('anb.action'),
            minWidth: 150,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex items-center">
                        <IconBtnBase onClick={() => history.push(`${path}/${row.id}/transactions`)} size="large">
                            <VisibilityIcon />
                        </IconBtnBase>

                        <div>
                            <IconButton
                                className="space-s-1.5"
                                onClick={(event) => setIsMenuOpen(event.currentTarget)}
                                size="small"
                                sx={{ borderRadius: 0 }}>
                                <DownloadRoundedIcon />
                            </IconButton>
                        </div>
                        <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
                            {formatsOfExporitngReport(row).map(({ url, label }) => (
                                <MenuItem>
                                    <a href={url} download>
                                        {label}
                                    </a>
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                );
            },
        },
    ];

    return eodStatementsQuery.isLoading ? (
        <Card className="mt-8">
            <TableSkeleton />
        </Card>
    ) : (
        <div className="mt-8">
            <Box sx={styles.tableSize}>
                <Paper>
                    <DataGrid
                        pagination
                        columns={columns}
                        paginationMode="server"
                        loading={eodStatementsQuery.isFetching}
                        rows={eodStatementsQuery.data?.data || []}
                        onPageChange={(newPage) => setPage(newPage + 1)}
                        rowCount={eodStatementsQuery.data?.meta.total}
                        pageSize={eodStatementsQuery.data?.meta.per_page}
                        page={page - 1}
                    />
                </Paper>
            </Box>
        </div>
    );
}
