import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { setProgress, CheckboxController, InputController, BtnBase, Btn } from '@aseel/common-ui';
import { Yup } from '@aseel/common-utils';
import { handleError } from '@aseel/http';

const schema = Yup.object().shape({
    name_en: Yup.string().required(),
    name_ar: Yup.string().required(),
});

const SAVE_CONTACT_US_REASONS_BTN = 'SAVE_CONTACT_US_REASONS_BTN';

const ContactUsReasonsAddEditModal = ({
    isAddEditContactUsModalOpen,
    closeAddEditContactUsReasonModal,
    isEdit,
    selectedContactUsReason,
}) => {
    const { control, handleSubmit, reset, setError } = useForm({
        defaultValues: { is_active: false },
        resolver: yupResolver(schema),
    });

    const { t } = useTranslation();

    useEffect(() => {
        if (isEdit && selectedContactUsReason) {
            reset({ ...selectedContactUsReason });
        } else {
            reset({ is_active: false });
        }
    }, [isEdit, selectedContactUsReason]);

    const handleContactUsReasonsFormSubmission = async (formData) => {
        setProgress(SAVE_CONTACT_US_REASONS_BTN, true);
        try {
            await http.request({
                method: isEdit ? 'PUT' : 'POST',
                url: isEdit ? `/contact-reasons/${selectedContactUsReason.id}` : '/contact-reasons',
                data: formData,
            });

            closeAddEditContactUsReasonModal();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(SAVE_CONTACT_US_REASONS_BTN, false);
    };

    return (
        <div>
            <Dialog
                open={isAddEditContactUsModalOpen}
                onClose={() => {
                    closeAddEditContactUsReasonModal();
                }}>
                <DialogContent>
                    <form onSubmit={handleSubmit(handleContactUsReasonsFormSubmission)}>
                        <Typography variant="h6" component="h1" className="mb-3">
                            {t('contactUsReasons.reason')}
                        </Typography>

                        <InputController
                            control={control}
                            name="name_en"
                            label={t('contactUsReasons.reasonTitle_en')}
                            className="mb-5"
                        />

                        <InputController
                            control={control}
                            name="name_ar"
                            label={t('contactUsReasons.reasonTitle_ar')}
                            className="mb-3"
                        />

                        <CheckboxController control={control} name="is_active" label={t('contactUsReasons.isActive')} />

                        <DialogActions className="flex justify-end">
                            <BtnBase
                                variant="text"
                                fullWidth={false}
                                onClick={() => {
                                    closeAddEditContactUsReasonModal();
                                }}>
                                {t('contactUsReasons.close')}
                            </BtnBase>
                            <Btn name={SAVE_CONTACT_US_REASONS_BTN} fullWidth={false} type="submit">
                                {t('contactUsReasons.save')}
                            </Btn>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ContactUsReasonsAddEditModal;
