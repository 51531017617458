import React from 'react';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router';
import Valuations from '@aseel/common-components/src/Funds/Valuations/Valuations';

function ValuationsPage() {
    const { fundId } = useParams();

    return (
        <Valuations
            fundId={fundId}
            getValuationsRequest={() => http.get(`/listings/${fundId}/valuations`).then((response) => response.data)}
            getValuationRequest={({ valuationId }) =>
                http.get(`/listings/${fundId}/valuations/${valuationId}`).then((response) => response.data.data)
            }
        />
    );
}

export default ValuationsPage;
