import React from 'react';
import { DataGrid } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import { Card, IconButton } from '@mui/material';
import { tabsValues } from '../BankAccountsPage';
import { approvalStatusModalTypes } from '@aseel/common-components';
import { ApprovalStatusChip } from '@aseel/common-components';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

export default function BankAccountsTable({ rows, currentTab, page, setPage, query, onShowBankInfo }) {
    const { t } = useTranslation();

    const bankAccountsColumns = [
        {
            field: 'name',
            headerName: t('bankAcc.name'),
            flex: 1,
        },
        {
            field: 'bank',
            headerName: t('bankAcc.bank'),
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('bankAcc.status'),
            flex: 1,
            renderCell: ({ row }) => (
                <ApprovalStatusChip
                    label={row.status.description}
                    status={row.status.value}
                    modalType={approvalStatusModalTypes.general}
                />
            ),
        },
        {
            field: 'reviewer_name',
            headerName: t('bankAcc.reviewerName'),
            flex: 1,
            renderCell: (row) => <div>{row.row.reviewer_name ?? '---'}</div>,
        },
        {
            field: 'authorized_entity',
            headerName: t('bankAcc.authorizedEntity'),
            flex: 1,
            renderCell: (row) => <div>{row.row.authorized_entity ?? '---'}</div>,
        },
        {
            field: 'actions',
            headerName: t('bankAcc.actions'),
            minWidth: 150,
            renderCell: (row) => (
                <div>
                    <IconButton onClick={() => onShowBankInfo(row.row)} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    if (currentTab === tabsValues.investor) {
        bankAccountsColumns.splice(4, 1);
    }

    return (
        <Card>
            <DataGrid
                columns={bankAccountsColumns}
                rows={rows}
                page={page}
                pageSize={query.data?.meta.per_page}
                rowCount={query.data?.meta.total}
                onPageChange={(newPage) => setPage(newPage)}
                paginationMode="server"
            />
        </Card>
    );
}
