import React from 'react';
import { Skeleton } from '@mui/material';
import { Card, CardContent, Typography } from '@mui/material';
import TableSkeleton from './TableSkeleton';

function ResourceListSkeleton({ hasBreadcrumbs = false }) {
    return (
        <div>
            <div className="px-3.5">
                {hasBreadcrumbs ? (
                    <Typography>
                        <Skeleton className="md:w-8/12 sm:w-5/12 w-4/12" />
                    </Typography>
                ) : null}
                <Typography variant="h3" className="pb-3">
                    <Skeleton className="md:w-5/12 sm:w-5/12 w-4/12" />
                </Typography>
                <Card>
                    <CardContent>
                        <TableSkeleton />
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default ResourceListSkeleton;
