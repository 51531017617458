import React, { useState } from 'react';
import { DataGrid, TableSkeleton } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import { Card, IconButton } from '@mui/material';
import { ApprovalStatusChip, approvalStatusModalTypes } from '@aseel/common-components';
import BankAccountInfoDialog from 'Components/BankAccountInfoDialog';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { useQuery } from 'react-query';
import { BANK_ACCOUNTS } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router';

export default function BankAccountsTab() {
    const { t } = useTranslation();
    const { investorId } = useParams();
    const [page, setPage] = useState(0);
    const [bankInfo, setBankInfo] = useState({
        show: false,
        info: null,
    });
    const bankAccountsQuery = useQuery([BANK_ACCOUNTS, { id: investorId }], () =>
        http
            .get(`/bank-accounts?${investorId}`, {
                params: {
                    page: page + 1,
                    accountable: `inv-${investorId}`,
                },
            })
            .then((response) => response.data),
    );

    if (bankAccountsQuery.isLoading) {
        return (
            <Card>
                <TableSkeleton />
            </Card>
        );
    }

    const handleCloseBankInfo = () => {
        setBankInfo({
            ...bankInfo,
            show: false,
        });
    };

    const onShowBankInfo = (row) => {
        setBankInfo({
            show: true,
            info: row,
        });
    };

    const bankAccountsColumns = [
        {
            field: 'name',
            headerName: t('bankAcc.name'),
            minWidth: 200,
        },
        {
            field: 'bank',
            headerName: t('bankAcc.bank'),
            minWidth: 200,
        },
        {
            field: 'status',
            headerName: t('bankAcc.status'),
            minWidth: 200,
            renderCell: ({ row }) => (
                <ApprovalStatusChip
                    label={row.status.description}
                    status={row.status.value}
                    modalType={approvalStatusModalTypes.general}
                />
            ),
        },
        {
            field: 'reviewer_name',
            headerName: t('bankAcc.reviewerName'),
            minWidth: 200,
            renderCell: (row) => <div>{row.row.reviewer_name ?? '---'}</div>,
        },

        {
            field: 'actions',
            headerName: t('bankAcc.actions'),
            minWidth: 120,
            renderCell: (row) => (
                <div>
                    <IconButton onClick={() => onShowBankInfo(row.row)} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <Card>
            <DataGrid
                page={page}
                paginationMode="server"
                columns={bankAccountsColumns}
                rows={bankAccountsQuery.data.data}
                pageSize={bankAccountsQuery.data?.meta.per_page}
                rowCount={bankAccountsQuery.data?.meta.total}
                onPageChange={(newPage) => setPage(newPage)}
            />

            <BankAccountInfoDialog
                bankInfo={bankInfo}
                isOpen={bankInfo.show}
                setBankInfo={setBankInfo}
                onClose={handleCloseBankInfo}
                refetchBankAccounts={bankAccountsQuery.refetch}
            />
        </Card>
    );
}
