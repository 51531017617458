import React, { useState } from 'react';
import { Card, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { CONTACT_US_REASONS } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import EditIcon from '@mui/icons-material/Edit';
import { BtnBase, IconBtnBase, DataGrid, ResourcesList } from '@aseel/common-ui';
import { ActiveChip } from '@aseel/common-components';
import ContactUsReasonsAddEditModal from './Components/ContactUsReasonsCreateEditModal';

const ContactUsReasonsPage = () => {
    const { t } = useTranslation();
    const contactUsReasonsQuery = useQuery(CONTACT_US_REASONS, () =>
        http.get('/contact-reasons').then(({ data }) => data.data),
    );

    const [isAddEditContactUsModalOpen, setIsAddEditContactUsModalOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedContactUsReason, setSelectedContactUsReason] = useState({});

    const columns = [
        {
            field: 'name',
            headerName: t('contactUsReasons.reason'),
            minWidth: 640,
            editable: false,
        },
        {
            field: 'action',
            headerName: t('contactUsReasons.action'),
            minWidth: 130,
            editable: false,
            renderCell: (row) => <ActiveChip isActive={row.row.is_active} />,
        },
        {
            field: 'status',
            headerName: t('contactUsReasons.status'),
            minWidth: 120,
            editable: false,
            renderCell: (row) => {
                return (
                    <IconBtnBase
                        onClick={() => {
                            openAddEditContactUsReasonModalForEdit(row.row);
                        }}
                        size="large">
                        <EditIcon />
                    </IconBtnBase>
                );
            },
        },
    ];

    const openAddEditContactUsReasonModal = () => {
        setIsAddEditContactUsModalOpen(true);
    };

    const closeAddEditContactUsReasonModal = () => {
        setIsAddEditContactUsModalOpen(false);
        setIsEdit(false);
        contactUsReasonsQuery.refetch();
    };

    const openAddEditContactUsReasonModalForEdit = (contactUsReason) => {
        setIsAddEditContactUsModalOpen(true);
        setIsEdit(true);
        setSelectedContactUsReason(contactUsReason);
    };

    return (
        <div>
            <ResourcesList
                headerProps={{
                    title: t('contactUsReasons.contactUs'),
                    EndSide: (
                        <BtnBase
                            fullWidth={false}
                            onClick={() => {
                                openAddEditContactUsReasonModal();
                            }}>
                            {t('contactUsReasons.addNewReason')}
                        </BtnBase>
                    ),
                }}
                Section={
                    <div className="w-full mt-3.5">
                        {contactUsReasonsQuery.isFetching && <LinearProgress />}

                        <Card>
                            <DataGrid
                                hideFooter={true} // There is no pagination
                                loading={contactUsReasonsQuery.isFetching}
                                rows={contactUsReasonsQuery?.data || []}
                                columns={columns}
                            />
                        </Card>

                        <ContactUsReasonsAddEditModal
                            isAddEditContactUsModalOpen={isAddEditContactUsModalOpen}
                            closeAddEditContactUsReasonModal={closeAddEditContactUsReasonModal}
                            selectedContactUsReason={selectedContactUsReason}
                            isEdit={isEdit}
                        />
                    </div>
                }
            />
        </div>
    );
};

export default ContactUsReasonsPage;
