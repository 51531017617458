import React from 'react';
import { useTranslation } from 'react-i18next';
import Logs from 'Components/Logs/Log';
import { WORKER_LOGS } from 'Constants/QueriesKeys';

const WorkerLogsPage = () => {
    const { t } = useTranslation();

    return <Logs title={t('logs.workerLogs')} url={'/worker-logs'} queryKey={WORKER_LOGS} />;
};

export default WorkerLogsPage;
