import React, { useState } from 'react';
import { i18n } from '@aseel/common-utils';
//import Balances from './Components/Balances';
import RoundedTabs from '@aseel/common-ui/src/Components/Tabs/RoundedTabs';
import EODStatementsTab from './Components/EODStatementsTab';
import EODTransactionsTab from './Components/EODTransactionsTab';

export const tabsValues = {
    eodStatements: 'eodStatements',
    transactions: 'transactions',
};

export const tabs = () => [
    {
        value: tabsValues.eodStatements,
        label: i18n.t('anb.eodStatements'),
        Component: EODStatementsTab,
    },
    {
        value: tabsValues.transactions,
        label: i18n.t('anb.eodTransactions'),
        Component: EODTransactionsTab,
        props: {
            hasBreadcrumbs: false,
            hasHeader: false,
            hasDatePicker: true,
        },
    },
];

export default function ANBPage() {
    const [currentTab, setCurrentTab] = useState(tabsValues.eodStatements);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const { Component, props } = tabs().find((tab) => tab.value === currentTab);

    return (
        <div>
            {/* <Balances /> */}
            <div className="px-2.5 sm:px-0 pt-8">
                <RoundedTabs tabs={tabs()} value={currentTab} onChange={handleTabChange} />
                <Component {...props} />
            </div>
        </div>
    );
}
