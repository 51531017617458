import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { Card, IconButton } from '@mui/material';
import { DataGrid, ResourcesList } from '@aseel/common-ui';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ResourceListSkeleton from 'Skeletons/ResourceListSkeleton';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { ApprovalStatusChip, approvalStatusModalTypes } from '@aseel/common-components';
import { PROFESSIONAL_UPGRADE_REQUESTS } from 'Constants/QueriesKeys';

export default function ProfessionalRequests() {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { path } = useRouteMatch();
    const [page, setPage] = useState(1);

    const upgradeRequestsQuery = useQuery([PROFESSIONAL_UPGRADE_REQUESTS, { page }], () =>
        http.get('/investors/upgrade-requests', { params: { page } }).then((response) => response.data),
    );

    const columns = [
        {
            field: 'id',
            headerName: t('professionalRequests.id'),
            editable: false,
        },
        {
            field: 'created_at',
            headerName: t('professionalRequests.createdAt'),
            flex: 1,
            minWidth: 180,
            editable: false,
        },
        {
            field: 'name',
            headerName: t('professionalRequests.name'),
            flex: 1,
            minWidth: 180,
            editable: false,
            renderCell: (row) => row.row.user.name,
        },
        {
            field: 'reviewer',
            headerName: t('professionalRequests.reviewer'),
            minWidth: 140,
            editable: false,
            renderCell: (row) => row.row.reviewer ?? '---',
        },
        {
            field: 'status',
            headerName: t('professionalRequests.status'),
            minWidth: 150,
            editable: false,
            renderCell: (row) => (
                <ApprovalStatusChip
                    label={row.row.status?.description}
                    status={row.row.status?.value}
                    modalType={approvalStatusModalTypes.investorUpgradeRequest}
                />
            ),
        },
        {
            field: 'action',
            headerName: t('professionalRequests.actions'),
            flex: 1,
            editable: false,
            renderCell: (row) => (
                <IconButton onClick={() => push(`${path}/${row.row.id}`)}>
                    <RemoveRedEyeRoundedIcon />
                </IconButton>
            ),
        },
    ];

    return upgradeRequestsQuery.isLoading ? (
        <ResourceListSkeleton />
    ) : (
        <div>
            <ResourcesList
                headerProps={{
                    title: t('professionalRequests.professionalRequests'),
                }}
                Section={
                    <Card className="mt-5">
                        <DataGrid
                            pagination
                            page={page + 1}
                            columns={columns}
                            paginationMode="server"
                            rows={upgradeRequestsQuery.data.data}
                            rowCount={upgradeRequestsQuery.data?.meta.total}
                            onPageChange={(newPage) => setPage(newPage + 1)}
                            pageSize={upgradeRequestsQuery.data?.meta.per_page}
                        />
                    </Card>
                }
            />
        </div>
    );
}
