import dayjs from 'dayjs';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TransactionsTable from './TransactionsTable';
import TableSkeleton from 'Skeletons/TableSkeleton';
import { i18n, url, Yup } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { ANB_TRANSACTIONS } from 'Constants/QueriesKeys';
import { Card, IconButton, Typography } from '@mui/material';
import { ExportReportDialog } from '@aseel/common-components';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Btn, BtnBase, SelectController, DateRangePickerController } from '@aseel/common-ui';

const filterSchema = Yup.object().shape({
    sort_by: Yup.string().required(),
    date_range: Yup.array(),
});

const BTN_FILTER = 'BTN_FILTER';

export const sortBy = {
    latest: 'latest',
    oldest: 'oldest',
};

const sortType = () => [
    {
        value: sortBy.latest,
        label: i18n.t('common.latest'),
    },
    {
        value: sortBy.oldest,
        label: i18n.t('common.oldest'),
    },
];

export default function EODTransactionsTab() {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [filterOptions, setFilterOptions] = useState(null);
    const [isExportReportsDialogOpen, setIsExportReportsDialogOpen] = useState(false);

    const filterForm = useForm({
        resolver: yupResolver(filterSchema),
        defaultValues: {
            sort_by: sortBy.latest,
            date_range: [null, null],
        },
    });

    const eodTransactionsQuery = useQuery([ANB_TRANSACTIONS, { page, filterOptions }], () =>
        http
            .get(`/anb/eod-transactions`, {
                params: {
                    page: page,
                    ...filterOptions,
                },
            })
            .then((response) => response.data),
    );

    const handleFilterOptions = (values) => {
        const dateRange = values.date_range;
        const startDate = dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : '';
        const endDate = dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : '';

        setFilterOptions({
            sort_by: values.sort_by,
            date_range: startDate || endDate ? `${startDate},${endDate}` : null,
        });
    };

    const handleResetFilterOptions = () => {
        setFilterOptions(null);
        filterForm.reset();
    };

    const reportDownloadUrl = (formData) => url(`api/admin/anb/eod-transactions/export`, formData);

    let exportReportDefaultValues = {};
    exportReportDefaultValues.date_range = filterForm.watch('date_range');

    return (
        <div>
            <form onSubmit={filterForm.handleSubmit(handleFilterOptions)}>
                <Card className="sm:flex items-center py-2.5 px-2.5 mt-8 sm:space-x-5 lg:space-x-10 space-y-2.5 sm:space-y-0">
                    <SelectController
                        name="sort_by"
                        options={sortType()}
                        label={t('common.sortBy')}
                        control={filterForm.control}
                        className="w-full sm:w-1/4"
                        menuPortalTarget={document.body}
                    />
                    <DateRangePickerController
                        maxDate={dayjs()}
                        name="date_range"
                        control={filterForm.control}
                        endText={t('common.endDate')}
                        startText={t('common.startDate')}
                    />
                    <div className="flex justify-end sm:block">
                        <Btn type="submit" name={BTN_FILTER} fullWidth={false} variant="text">
                            {t('anb.filter')}
                        </Btn>
                        <BtnBase fullWidth={false} variant="text" onClick={handleResetFilterOptions}>
                            {t('anb.reset')}
                        </BtnBase>
                    </div>
                </Card>
            </form>

            {eodTransactionsQuery.isLoading ? (
                <Card className="mt-3.5">
                    <TableSkeleton />
                </Card>
            ) : (
                <div className="mt-3.5">
                    <div className="flex items-center justify-between">
                        <Typography variant="subtitle1" color="textSecondary">
                            {t('anb.transactions')}
                        </Typography>
                        <IconButton onClick={() => setIsExportReportsDialogOpen(!isExportReportsDialogOpen)}>
                            <DownloadRoundedIcon />
                        </IconButton>
                    </div>
                    <Card>
                        <ExportReportDialog
                            urlGenerator={reportDownloadUrl}
                            isOpen={isExportReportsDialogOpen}
                            defaultValues={exportReportDefaultValues}
                            isExportReportsDialogOpen={isExportReportsDialogOpen}
                            setIsExportReportsDialogOpen={setIsExportReportsDialogOpen}
                            onClose={() => setIsExportReportsDialogOpen(!isExportReportsDialogOpen)}
                        />
                        <TransactionsTable
                            page={page}
                            setPage={setPage}
                            rows={eodTransactionsQuery.data?.data || []}
                            rowCount={eodTransactionsQuery.data?.meta.total}
                            pageSize={eodTransactionsQuery.data?.meta.per_page}
                        />
                    </Card>
                </div>
            )}
        </div>
    );
}
