import React, { useState } from 'react';
import { http } from 'Utils/Http/Http';
import { ENQUIRIES, ENQUIRY_REPLIES } from 'Constants/QueriesKeys';
import EnquiryActionModal from './Components/EnquiryActionModal';
import EnquiryPaginatedTable from './Components/EnquiryPaginatedTable';
import { useQuery } from 'react-query';
import { ResourceListSkeleton } from '@aseel/common-ui';

// Component starts here
export default function Enquiries() {
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [isDrawarOpen, setIsDrawarOpen] = useState(false);
    const [page, setPage] = useState(1);

    const enquiriesQuery = useQuery([ENQUIRIES, { page }], () =>
        http.get('/enquiries', { params: { page } }).then(({ data }) => data),
    );

    const enquiryRepliesQuery = useQuery(
        [ENQUIRY_REPLIES, { id: selectedEnquiry?.id }],
        () => http.get(`/enquiries/${selectedEnquiry?.id}`).then(({ data }) => data.data?.replies),
        {
            enabled: Boolean(selectedEnquiry?.id),
        },
    );

    const handleActionButtonClick = (rowData) => {
        setSelectedEnquiry(rowData);
        setIsDrawarOpen(true);
    };

    if (enquiriesQuery.isLoading) {
        return <ResourceListSkeleton />;
    }

    return (
        <>
            <EnquiryPaginatedTable
                enquiriesQuery={enquiriesQuery}
                handleActionButtonClick={handleActionButtonClick}
                isEnquiryListLoading={enquiriesQuery.isFetching}
                page={page}
                setPage={setPage}
            />

            <EnquiryActionModal
                isDrawarOpen={isDrawarOpen}
                setIsDrawarOpen={setIsDrawarOpen}
                selectedEnquiry={selectedEnquiry}
                enquiryRepliesQuery={enquiryRepliesQuery}
            />
        </>
    );
}
