import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LiveHelpRoundedIcon from '@mui/icons-material/LiveHelpRounded';
import CategoryIcon from '@mui/icons-material/Category';
import TocIcon from '@mui/icons-material/Toc';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import ContactSupport from '@mui/icons-material/ContactSupport';
import SubjectIcon from '@mui/icons-material/Subject';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import RingVolumeRoundedIcon from '@mui/icons-material/RingVolumeRounded';
import HomeWorkRoundedIcon from '@mui/icons-material/HomeWorkRounded';
import UpgradeRoundedIcon from '@mui/icons-material/UpgradeRounded';

export const NavLinks = [
    { name: 'Home', path: '/app/dashboard', current: true, Icon: HomeRoundedIcon },
    { name: 'Customer Support', path: '/app/enquiries', current: false, Icon: LiveHelpRoundedIcon },
    { name: 'Investors', path: '/app/investors', current: false, Icon: GroupRoundedIcon },
    { name: 'Professional Requests', path: '/app/professional-requests', current: false, Icon: UpgradeRoundedIcon },
    { name: 'Fund Types', path: '/app/fund-types', current: false, Icon: CategoryIcon },
    { name: 'Pages', path: '/app/pages', current: false, Icon: TocIcon },
    { name: 'Bank Accounts', path: '/app/bank-accounts',  current: false, Icon: AccountBalanceRoundedIcon },
    { name: 'Funds', path: '/app/funds', current: false, Icon: HomeWorkRoundedIcon },
    { name: 'Authorized Entities', path: '/app/authorized-entities', current: false, Icon: DomainRoundedIcon },
    { name: 'FAQs', path: '/app/faqs', current: false, Icon: ContactSupport },
    { name: 'Contact Us Reasons', path: '/app/contact-reasons', current: false, Icon: RingVolumeRoundedIcon },
    { name: 'ANB', path: '/app/anb', current: false, Icon: InsertDriveFileIcon },
    { name: 'Logs', path: '/app/logs', current: false, Icon: SubjectIcon },
    { name: 'Worker Logs', path: '/app/worker-logs', current: false, Icon: SettingsIcon },

];
