import i18n from 'Utils/I18n';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { handleError } from '@aseel/http';
import { setProgress } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import { INVESTORS } from 'Constants/QueriesKeys';
import { Divider, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import InformationTab from './TabsSection/InformationTab';
import BankAccountsTab from './TabsSection/BankAccountsTab';
import RoundedTabs from '@aseel/common-ui/src/Components/Tabs/RoundedTabs';
import InvestorInfoPageSkeleton from './Skeletons/InvestorInfoPageSkeleton';
import BalanceAndTransactionsTab from './TabsSection/BalanceAndTransactionsTab';
import InvestmentsTab from './TabsSection/InvestmentsTab';

const STATUS_BTN = 'STATUS_BTN';

const schema = Yup.object().shape({
    status: Yup.number().required().nullable(),
});

export const tabsValue = {
    information: 'information',
    bankAccounts: 'bankAccounts',
    balanceAndTransactions: 'balanceAndTransactions',
    investments: 'investments',
};

export default function InvestorInfoPage() {
    const { investorId } = useParams();
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState(tabsValue.information);

    const investorInfoQuery = useQuery([INVESTORS, { id: investorId }], () =>
        http.get(`/investors/${investorId}`).then((response) => response.data.data),
    );

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const investorInfo = investorInfoQuery.data;

    const handleSubmitStatus = async (values) => {
        setProgress(STATUS_BTN, true);
        try {
            await http.put(`/investors/${investorId}/status`, values);
            investorInfoQuery.refetch();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(STATUS_BTN, false);
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    if (investorInfoQuery.isLoading) {
        return <InvestorInfoPageSkeleton />;
    }

    const absherInformation = investorInfo?.absher;

    const tabs = [
        {
            value: tabsValue.information,
            label: t(`investor.${tabsValue.information}`),
            Component: InformationTab,
            props: {
                absherInformation,
                investorInfo,
                handleSubmit,
                control,
                handleSubmitStatus,
                btnName: STATUS_BTN,
            },
        },
        {
            value: tabsValue.bankAccounts,
            label: t(`investor.${tabsValue.bankAccounts}`),
            Component: BankAccountsTab,
        },
        {
            value: tabsValue.balanceAndTransactions,
            label: t(`investor.${tabsValue.balanceAndTransactions}`),
            Component: BalanceAndTransactionsTab,
            props: {
                investorInfo,
            },
        },
        {
            value: tabsValue.investments,
            label: t(`investor.${tabsValue.investments}`),
            Component: InvestmentsTab,
        },
    ];

    const { Component, props } = tabs.find((el) => el.value === currentTab);

    return (
        <div className="space-y-5 px-2.5">
            <div>
                <Typography variant="h4">{t('investor.investorInfo', { id: investorId })}</Typography>
            </div>
            <Divider />
            <RoundedTabs
                tabs={tabs}
                value={currentTab}
                centered={false}
                variant="scrollable"
                onChange={handleTabChange}
            />
            <Component {...props} />
        </div>
    );
}
