import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { Yup } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { AUTHORIZED_ENTITIES } from 'Constants/QueriesKeys';
import { Btn, InputController, setProgress, FormSkeleton, UploaderController } from '@aseel/common-ui';

const CREATE_EDIT_BTN = 'CREATE_EDIT_BTN';

const schema = Yup.object().shape({
    name: Yup.string().required(),
    cr_number: Yup.string().required(),
    description: Yup.string().required(),
    cr: Yup.array().length(1, 'uploadCR').required(),
    cma_license: Yup.array().length(1, 'uploadCMALicense').required(),
});

function CreateEditEntityDialog({ isEdit, isOpen, setIsDialogOpen, selectedEntity, authorizedEntitiesQuery }) {
    const { t } = useTranslation();
    const [isUploading, setIsUploading] = useState(false);

    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            phone_country_code: 'SA',
            cma_license: [],
            cr: [],
        },
    });

    const authorizedEntityQuery = useQuery(
        [AUTHORIZED_ENTITIES, { id: selectedEntity?.id }],
        () => http.get(`/authorized-entities/${selectedEntity?.id}`).then((response) => response.data.data),
        {
            enabled: Boolean(selectedEntity?.id),
            onSuccess: (data) => {
                reset({ ...data });
            },
        },
    );

    useEffect(() => {
        if (isOpen && isEdit === false) {
            reset();
        }
    }, [isOpen]);

    const handleCloseCreateEditDialog = () => {
        setIsDialogOpen(false);
    };

    const handleFormSubmission = async (values) => {
        const form = new FormData();
        Object.keys(values).forEach((key) => {
            if (key === 'cr') {
                values.cr.forEach((e) => {
                    if (e.file instanceof File) {
                        form.append(`cr[${e.id}]`, e.file);
                    }
                });
            } else if (key === 'cma_license') {
                if (values.cma_license[0] instanceof File) {
                    form.append('cma_license', values[key][0]);
                }
            } else {
                form.append(key, values[key]);
            }
        });

        if (isEdit) {
            form.append('_method', 'put');
        }

        setProgress(CREATE_EDIT_BTN, true);
        setIsUploading(true);

        try {
            await http.request({
                method: 'POST',
                url: isEdit ? `/authorized-entities/${selectedEntity?.id}` : `/authorized-entities`,
                data: form,
            });
            authorizedEntityQuery.refetch();
            authorizedEntitiesQuery.refetch();
            setIsDialogOpen(false);
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CREATE_EDIT_BTN, false);
        setIsUploading(false);
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseCreateEditDialog} fullWidth>
            <DialogContent>
                {authorizedEntityQuery.isLoading ? (
                    <FormSkeleton inputsCount={5} />
                ) : (
                    <form onSubmit={handleSubmit(handleFormSubmission)}>
                        <div className="mb-3 font-bold text-2xl">
                            {isEdit
                                ? t('authEntities.edit', {
                                      name: `${selectedEntity?.name}`,
                                  })
                                : t('authEntities.createEntity')}
                        </div>

                        <div className="space-y-4">
                            <InputController control={control} name="name" label={t('authEntities.name')} />
                            <InputController control={control} name="cr_number" label={t('authEntities.crNumber')} />
                            <InputController
                                multiline
                                rows="4"
                                control={control}
                                name="description"
                                label={t('authEntities.description')}
                            />
                            <UploaderController
                                name="cr"
                                control={control}
                                disabled={isUploading}
                                label={t('authEntities.cr')}
                            />
                            <UploaderController
                                control={control}
                                name="cma_license"
                                disabled={isUploading}
                                label={t('authEntities.cmaLisence')}
                                single
                            />
                        </div>
                        <div className="flex justify-end mt-5">
                            <Btn type="submit" name={CREATE_EDIT_BTN} fullWidth={false}>
                                {isEdit ? t('common.update') : t('common.create')}
                            </Btn>
                        </div>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default CreateEditEntityDialog;
