import dayjs from 'dayjs';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { isAxiosError } from '@aseel/http';
import AMLModal from './Components/AMLModal';
import { useTranslation } from 'react-i18next';
import { updateToast } from '@aseel/common-utils';
import BalanceModal from './Components/BalanceModal';
import InvestorInfoDialog from './InvestorInfoDialog';
import { ConfirmationDialog } from '@aseel/common-ui';
import { INVESTORS } from '../../Constants/QueriesKeys';
import { useHistory, useRouteMatch } from 'react-router';
import InvestorPaginatedTable from './Components/InvestorPaginatedTable';

export default function InvestorsPage() {
    const [selectedInvestor, setSelectedInvestor] = useState(null);
    const [isDrawarOpen, setIsDrawarOpen] = useState(false);
    const [isBalanceOpen, setIsBalanceOpen] = useState(false);
    const [isAmlOpen, setIsAmlOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'));
    const { t } = useTranslation();
    const history = useHistory();
    const { path } = useRouteMatch();

    const investorQuery = useQuery([INVESTORS, { page }], () =>
        http.get('/investors', { params: { page } }).then(({ data }) => data),
    );

    const handleShowUserInfo = (rowData) => {
        setSelectedInvestor(rowData);
        setIsDrawarOpen(true);
    };

    const handleBalanceOpen = (rowData) => {
        setSelectedInvestor(rowData);
        setIsBalanceOpen(true);
    };

    const handleAmlOpen = (rowData) => {
        setSelectedInvestor(rowData);
        setIsAmlOpen(true);
    };

    const handleUpdateStatus = (row) => {
        history.push(`${path}/${row.id}`);
    };

    const handleAmlReassessment = (investorData) => {
        let toastId = null;
        ConfirmationDialog({
            title: t('investor.amlReassessmentWarning.title'),
            text: t('investor.amlReassessmentWarning.text', { id: investorData.id }),
        })
            .then(() => {
                toastId = toast.loading(t('messages.amlReassessmentBeingSent'), {
                    updateId: 1,
                });
                return http.post(`/aml/users/${investorData.id}/check`);
            })
            .then(() => {
                updateToast(toastId, { type: 'success', render: t('messages.amlReassessmentRequestSent') });
            })
            .catch((error) => {
                if (isAxiosError(error) && toastId) {
                    updateToast(toastId, { type: 'error', render: t('messages.failedAmlReassessmentRequest') });
                }
            });
    };

    return (
        <>
            <InvestorPaginatedTable
                investorQuery={investorQuery}
                onShowUserInfo={handleShowUserInfo}
                handleBalanceOpen={handleBalanceOpen}
                handleAmlOpen={handleAmlOpen}
                onAmlReassessment={handleAmlReassessment}
                onUpdateStatus={handleUpdateStatus}
                isEnquiryListLoading={investorQuery.isFetching}
                page={page}
                setPage={setPage}
            />
            <InvestorInfoDialog
                isDrawarOpen={isDrawarOpen}
                setIsDrawarOpen={setIsDrawarOpen}
                selectedInvestor={selectedInvestor}
            />
            <BalanceModal investor={selectedInvestor} isDrawarOpen={isBalanceOpen} setIsDrawarOpen={setIsBalanceOpen} />
            <AMLModal
                investor={selectedInvestor}
                isDrawarOpen={isAmlOpen}
                setIsDrawarOpen={setIsAmlOpen}
                startDate={startDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
            />
        </>
    );
}
