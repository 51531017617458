import TitleWithText from '@aseel/common-components/src/Funds/Components/TitleWithText';
import { Card, CardContent, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import React from 'react';

export default function InvestorInfoPageSkeleton() {
    return (
        <div className="space-y-5 px-2.5">
            <Typography variant="h4">
                <Skeleton className="w-1/4" />
            </Typography>
            <Card>
                <CardContent>
                    <div className="grid sm:grid-cols-3 grid-cols-1 gap-10 p-6">
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                        <TitleWithText
                            title={<Skeleton className="md:w-1/2" />}
                            text={<Skeleton className="md:w-1/2 w-9/12" />}
                        />
                    </div>
                </CardContent>
            </Card>
            <Card className="px-3 py-5">
                <Typography variant="h3">
                    <Skeleton className="w-1/4" />
                </Typography>
                <CardContent className="py-2">
                    <Typography variant="h2">
                        <Skeleton />
                    </Typography>
                    <div className="flex justify-end">
                        <Skeleton className="w-1/12 h-10" />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
