import React from 'react';
import { Dialog, DialogContent, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { INVESTORS } from 'Constants/QueriesKeys';
import InvestorInfoSkeleton from './Skeletons/InvestorInfoSkeleton';
import InvestorPersonalInformation from './Components/InvestorPersonalInformation';
import InvestorProfileInformation from './Components/InvestorProfileInformation';
import InvestorPersonalInformationFromAbsher from './Components/InvestorPersonalInformationFromAbsher';
import InvestorAddressInformationFromAbsher from './Components/InvestorAddressInformationFromAbsher';

// Component goes here
const InvestorInfoDialog = ({ isDrawarOpen, setIsDrawarOpen, selectedInvestor }) => {
    const { t } = useTranslation();

    const investorInfoQuery = useQuery(
        [INVESTORS, { id: selectedInvestor?.id }],
        () => http.get(`/investors/${selectedInvestor?.id}`).then((response) => response.data.data),
        {
            enabled: Boolean(selectedInvestor?.id),
        },
    );

    const investorInformation = investorInfoQuery?.data;
    const absherInformation = investorInformation?.absher;

    // Handlers
    const closeModal = () => {
        setIsDrawarOpen(false);
    };

    return (
        <Dialog open={isDrawarOpen} onClose={closeModal} maxWidth="sm" fullWidth>
            <DialogContent className="pb-6">
                {investorInfoQuery.isLoading ? (
                    <InvestorInfoSkeleton />
                ) : (
                    <>
                        <div className="flex items-center justify-between mb-4">
                            <div>
                                <Typography variant="h6">{t('investor.investorInfo')}</Typography>
                            </div>
                            <div>
                                <IconButton
                                    onClick={() => {
                                        closeModal(false);
                                    }}
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        {absherInformation?.is_verified ? (
                            <>
                                <InvestorPersonalInformationFromAbsher
                                    absherInformation={absherInformation}
                                    className="grid grid-cols-2 gap-3"
                                />
                                <Divider className="my-5" />
                                <InvestorAddressInformationFromAbsher
                                    addressInfo={absherInformation.address}
                                    className="space-y-5"
                                    infoContainerClassName="grid grid-cols-2 gap-3 my-5"
                                />
                                <Divider className="mt-5" />
                            </>
                        ) : null}
                        <InvestorPersonalInformation
                            investorInfo={investorInformation}
                            className="grid grid-cols-2 gap-3 my-5"
                        />
                        {investorInformation?.profile ? (
                            <>
                                <Divider />
                                <InvestorProfileInformation
                                    investorInfo={investorInformation}
                                    className="py-5 space-y-5"
                                />
                            </>
                        ) : null}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default InvestorInfoDialog;
