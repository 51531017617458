import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function InvestorPersonalInformationFromAbsher({ absherInformation, className }) {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.firstName')}
                </Typography>
                <Typography variant="body1">{absherInformation.first_name}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.secondName')}
                </Typography>
                <Typography variant="body1">{absherInformation.second_name}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.thirdName')}
                </Typography>
                <Typography variant="body1">{absherInformation.third_name}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.lastName')}
                </Typography>
                <Typography variant="body1">{absherInformation.forth_name}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.nationalId')}
                </Typography>
                <Typography variant="body1">{absherInformation.nin}</Typography>
            </div>
            {absherInformation.id_expiry_date ? (
                <div>
                    <Typography variant="subtitle2" color="textSecondary">
                        {t('investor.idExpiryDate')}
                    </Typography>
                    <Typography variant="body1">{absherInformation.id_expiry_date}</Typography>
                </div>
            ) : null}
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.gender')}
                </Typography>
                <Typography variant="body1">{t(`common.${absherInformation.gender}`)}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.dateOfBirth')}
                </Typography>
                <Typography variant="body1">{absherInformation.birth_date}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.nationality')}
                </Typography>
                <Typography variant="body1">{absherInformation.nationality}</Typography>
            </div>
        </div>
    );
}
