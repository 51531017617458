import React from 'react';
import { Scope } from '@aseel/constants';
import FundCreateEditForm from '@aseel/common-components/src/Funds/CreateOrUpdateFund/FundCreateEditForm';
import { useParams } from 'react-router';
import { http } from 'Utils/Http/Http';

export default function FundCreateEditPage() {
    const { fundId } = useParams();

    const uploadImagesRequest = ({ returnedFundId, formData }) => {
        return http.post(`/listings/${returnedFundId}/images`, formData);
    };

    const uploadFilesRequest = ({ returnedFundId, formData }) => {
        return http.post(`/listings/${returnedFundId}/files`, formData);
    };

    return (
        <div>
            <FundCreateEditForm
                fundId={fundId}
                scope={Scope.admin}
                uploadFilesRequest={uploadFilesRequest}
                uploadImagesRequest={uploadImagesRequest}
                getFundRequest={() => http.get(`/listings/${fundId}?edit=1`).then((response) => response.data.data)}
            />
        </div>
    );
}
