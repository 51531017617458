import React from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useForm } from 'react-hook-form';
import { handleError } from '@aseel/http';
import { NavLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18n, Yup } from '@aseel/common-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { investorUpgradeRequestStatus } from '@aseel/constants';
import { PROFESSIONAL_UPGRADE_REQUESTS } from 'Constants/QueriesKeys';
import { Breadcrumbs, Card, CardContent, Divider, Link, Typography } from '@mui/material';
import { Btn, SelectController, setProgress, InputController, IconBtnBase } from '@aseel/common-ui';
import { ApprovalStatusChip, approvalStatusModalTypes } from '@aseel/common-components';
import ShowProfessionalRequestSkeleton from './Skeletons/ShowProfessionalRequestSkeleton';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const STATUS_BTN = 'STATUS_BTN';

const schema = Yup.object().shape({
    status: Yup.number().required().nullable(),
});

const statusDescriptions = () => [
    {
        value: investorUpgradeRequestStatus.pendingReview,
        label: i18n.t('common.pendingReview'),
    },
    {
        value: investorUpgradeRequestStatus.inReview,
        label: i18n.t('common.inReview'),
    },
    {
        value: investorUpgradeRequestStatus.approved,
        label: i18n.t('common.approved'),
    },
    {
        value: investorUpgradeRequestStatus.rejected,
        label: i18n.t('common.rejected'),
    },
];

export default function ShowProfessionalRequestPage() {
    const { professionalRequestId } = useParams();
    const { t } = useTranslation();

    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });

    const upgradeRequestQuery = useQuery(
        [PROFESSIONAL_UPGRADE_REQUESTS, { id: professionalRequestId }],
        () => http.get(`/investors/upgrade-requests/${professionalRequestId}`).then((response) => response.data.data),
        {
            enabled: Boolean(professionalRequestId),
        },
    );

    if (upgradeRequestQuery.isLoading) {
        return <ShowProfessionalRequestSkeleton />;
    }

    const upgradeRequest = upgradeRequestQuery?.data;

    const handleSubmitStatus = async (values) => {
        setProgress(STATUS_BTN, true);
        try {
            await http.put(`/investors/upgrade-requests/${professionalRequestId}/status`, values);
            upgradeRequestQuery.refetch();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(STATUS_BTN, false);
    };

    return (
        <div className="space-y-5 px-2.5 sm:px-0">
            <Breadcrumbs className="mb-4">
                <Link component={NavLink} to="/app/professional-requests">
                    {t('professionalRequests.professionalRequests')}
                </Link>
                <Typography color="textPrimary">
                    {t('professionalRequests.request', { requestId: professionalRequestId })}
                </Typography>
            </Breadcrumbs>
            <div className="space-y-8">
                <Card>
                    <div>
                        <div className="p-5">
                            <Typography variant="body2" color="textSecondary">
                                {t('professionalRequests.createdAt')}
                            </Typography>
                            <Typography>{upgradeRequest.created_at}</Typography>
                        </div>
                        <Divider />
                        {upgradeRequest?.items.map((item, i) => (
                            <>
                                <div className="p-5 space-y-3.5">
                                    <div>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('professionalRequests.condition', { conditionIndex: i + 1 })}
                                        </Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                    <div>
                                        <Typography variant="body2" color="textSecondary" className="mb-2">
                                            {t('professionalRequests.attachedDocuments')}
                                        </Typography>
                                        <div className="space-y-3">
                                            {item.files.map((item) => (
                                                <div className="flex justify-between">
                                                    <Typography>{item.name}</Typography>
                                                    <div>
                                                        <IconBtnBase component="a" href={item.url} download>
                                                            <DownloadRoundedIcon />
                                                        </IconBtnBase>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {i + 1 === upgradeRequest.items.length ? null : <Divider />}
                            </>
                        ))}
                    </div>
                </Card>
                <Card>
                    <Card>
                        <div className="px-4 py-5">
                            <Typography variant="h5">{t('professionalRequests.updateStatus')}</Typography>
                        </div>
                        <Divider />
                        <CardContent className="py-5 space-y-3.5">
                            <div className="space-y-1.5">
                                <Typography variant="body2" color="textSecondary">
                                    {t('professionalRequests.currentStatus')}
                                </Typography>
                                <ApprovalStatusChip
                                    label={upgradeRequest.status.description}
                                    status={upgradeRequest.status.value}
                                    modalType={approvalStatusModalTypes.investorUpgradeRequest}
                                />
                            </div>
                            <form onSubmit={handleSubmit(handleSubmitStatus)}>
                                <div className="space-y-3">
                                    <SelectController
                                        name="status"
                                        control={control}
                                        className="pt-2.5"
                                        label={t('professionalRequests.status')}
                                        options={statusDescriptions()}
                                        menuPortalTarget={document.body}
                                    />
                                    <InputController
                                        name="comment"
                                        control={control}
                                        label={t('professionalRequests.comment')}
                                        multiline
                                        rows={7}
                                    />
                                </div>
                                <div className="flex justify-end mt-2.5">
                                    <Btn type="submit" name={STATUS_BTN} fullWidth={false}>
                                        {t('common.update')}
                                    </Btn>
                                </div>
                            </form>
                        </CardContent>
                    </Card>
                </Card>
            </div>
        </div>
    );
}
