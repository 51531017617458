import React from 'react';
import { i18n } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import { approvalStatus } from '@aseel/constants';
import { ApprovalStatusChip, approvalStatusModalTypes } from '@aseel/common-components';
import { Alert, Card, CardContent, Divider, Typography } from '@mui/material';
import { Btn, InputController, SelectController } from '@aseel/common-ui';
import InvestorProfileInformation from '../Components/InvestorProfileInformation';
import InvestorPersonalInformation from '../Components/InvestorPersonalInformation';
import InvestorAddressInformationFromAbsher from '../Components/InvestorAddressInformationFromAbsher';
import InvestorPersonalInformationFromAbsher from '../Components/InvestorPersonalInformationFromAbsher';

const statusDescriptions = () => [
    {
        value: approvalStatus.pendingReview,
        label: i18n.t('common.pendingReview'),
    },
    {
        value: approvalStatus.inReview,
        label: i18n.t('common.inReview'),
    },
    {
        value: approvalStatus.approved,
        label: i18n.t('common.approved'),
    },
    {
        value: approvalStatus.rejected,
        label: i18n.t('common.rejected'),
    },
];

export default function InformationTab({
    btnName,
    control,
    investorInfo,
    handleSubmit,
    absherInformation,
    handleSubmitStatus,
}) {
    const { t } = useTranslation();

    return (
        <>
            <Card>
                {absherInformation.is_identity_verified ? (
                    <>
                        <InvestorPersonalInformationFromAbsher
                            absherInformation={absherInformation.identity}
                            className="grid gap-5 px-6 pt-6 sm:grid-cols-3"
                        />
                        <Divider className="my-5" />
                        <InvestorAddressInformationFromAbsher
                            addressInfo={absherInformation.identity.addresses}
                            className="px-6 space-y-5"
                            infoContainerClassName="grid sm:grid-cols-3 gap-5"
                        />
                        <Divider className="my-5" />
                    </>
                ) : null}
                <InvestorPersonalInformation investorInfo={investorInfo} className="grid gap-5 p-6 sm:grid-cols-3" />
                {investorInfo?.profile ? (
                    <>
                        <Divider />
                        <InvestorProfileInformation
                            investorInfo={investorInfo}
                            className="grid gap-5 pt-6 pb-6 pl-6 sm:grid-cols-2 sm:gap-10"
                        />
                    </>
                ) : null}
            </Card>
            <form onSubmit={handleSubmit(handleSubmitStatus)} className="w-full space-y-2.5">
                <Card>
                    <div className="px-4 py-5">
                        <Typography variant="h5">{t('authEntities.updateStatus')}</Typography>
                    </div>
                    <Divider />
                    <CardContent className="py-5 space-y-3.5">
                        <div className="space-y-1.5">
                            <Typography variant="body2" color="textSecondary">
                                {t('investor.currentStatus')}
                            </Typography>
                            <ApprovalStatusChip
                                label={investorInfo.status.description}
                                status={investorInfo.status.value}
                                modalType={approvalStatusModalTypes.investor}
                            />
                        </div>
                        {investorInfo.is_kyc_completed ? null : (
                            <Alert severity="warning">{t('investor.kycNotCompleted')}</Alert>
                        )}
                        <SelectController
                            name="status"
                            control={control}
                            className="pt-2.5"
                            label={t('bankAcc.status')}
                            options={statusDescriptions()}
                            menuPortalTarget={document.body}
                        />

                        <InputController
                            name="comment"
                            control={control}
                            label={t('bankAcc.comment')}
                            multiline
                            rows={7}
                        />
                        <div className="flex justify-end">
                            <Btn
                                type="submit"
                                name={btnName}
                                fullWidth={false}
                                disabled={!investorInfo?.is_kyc_completed}>
                                {t('common.update')}
                            </Btn>
                        </div>
                    </CardContent>
                </Card>
            </form>
        </>
    );
}
