import useAuth from 'Hooks/UseAuth';
import { http } from 'Utils/Http/Http';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { handleError } from '@aseel/http';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getRecaptchaToken } from '@aseel/common-utils';
import { Link as RouterLink } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Card, CardContent, Typography, Link } from '@mui/material';
import ProjectLogoDark from '@aseel/common-assets/assets/aseel-logo-dark.png';
import { InputController, PasswordInputController, Btn, CheckboxController, setProgress } from '@aseel/common-ui';

const SIGN_IN_BTN = 'SIGN_IN_BTN';

const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
    remember_me: Yup.bool().required(),
});

export default function SignInPage() {
    const { control, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            remember_me: false,
        },
    });
    const auth = useAuth();
    const history = useHistory();
    const { t } = useTranslation();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleLogin = async (values) => {
        setProgress(SIGN_IN_BTN, true);
        try {
            const token = await getRecaptchaToken(executeRecaptcha);

            await http.post(
                '/api/login',
                { ...values, g_token: token },
                {
                    baseURL: process.env.REACT_APP_BASE_URL,
                },
            );

            await auth.refreshCsrf();
            await auth.fetchUserProfile();
            setProgress(SIGN_IN_BTN, false);
            history.push('/app/dashboard');
        } catch (error) {
            handleError(error, { setError });
            setProgress(SIGN_IN_BTN, false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <Card elevation={5} className="w-full max-w-md">
                <CardContent className="p-8">
                    <div>
                        <img className="w-auto mx-auto h-14" src={ProjectLogoDark} alt="Aseel Logo" />
                        <Typography variant="h6" className="mt-6 text-center text-gray-900">
                            {t('signIn.signInToYourAccount')}
                        </Typography>
                    </div>

                    <div className="mt-8">
                        <div className="mt-6">
                            <form className="space-y-4" onSubmit={handleSubmit(handleLogin)}>
                                <InputController control={control} name="email" label={t('formLabels.email')} />
                                <PasswordInputController
                                    control={control}
                                    name="password"
                                    label={t('formLabels.password')}
                                />

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <CheckboxController
                                            label={t('signIn.rememberMe')}
                                            name="remember_me"
                                            control={control}
                                        />
                                    </div>

                                    <Link component={RouterLink} to="/forgot-password">
                                        {t('signIn.forgotYourPassword')}
                                    </Link>
                                </div>

                                <Btn type="submit" name={SIGN_IN_BTN}>
                                    {t('signIn.signIn')}
                                </Btn>
                            </form>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
