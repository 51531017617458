import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function ValuationsInfoDialog({ isOpen, onClose, data }) {
    const { t } = useTranslation();

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogContent className="space-y-5 mb-5">
                <Typography variant="h5">{t('funds.valuations.valuationsInfo')}</Typography>
                <div className="grid grid-cols-2 gap-5">
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            {t('funds.valuations.date')}
                        </Typography>
                        <Typography>{data?.date}</Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            {t('funds.valuations.created_at')}
                        </Typography>
                        <Typography>{data?.created_at}</Typography>
                    </div>
                    <div>
                        <Typography color="textSecondary" variant="body2">
                            {t('funds.valuations.value')}
                        </Typography>
                        <Typography>{data?.value_fmt}</Typography>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default ValuationsInfoDialog;
