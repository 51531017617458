import React from 'react';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, Typography } from '@mui/material';
import { AUTHORIZED_ENTITY_USERS } from 'Constants/QueriesKeys';
import UserInfoDialogSkeleton from '../Skeletons/UserInfoDialogSkeleton';

function UserInfoDialog({ isOpen, setIsUserDialogInfoOpen, authorizedEntityId, selectedUser }) {
    const userInfoQuery = useQuery(
        [AUTHORIZED_ENTITY_USERS, { id: authorizedEntityId, userId: selectedUser?.id }],
        () =>
            http
                .get(`/authorized-entities/${authorizedEntityId}/users/${selectedUser?.id}`)
                .then((response) => response.data.data),
        {
            enabled: Boolean(selectedUser?.id),
        },
    );

    const { t } = useTranslation();

    const handleCloseUsersDialog = () => {
        setIsUserDialogInfoOpen(false);
    };
    return (
        <Dialog open={isOpen} onClose={handleCloseUsersDialog} fullWidth>
            <DialogContent>
                {userInfoQuery.isLoading ? (
                    <UserInfoDialogSkeleton />
                ) : (
                    <>
                        <div className="mb-3">
                            <Typography variant="h5">{t('authEntities.users.userInformation')}</Typography>
                        </div>
                        <div className="grid grid-cols-2 gap-4 pb-3">
                            <div>
                                <Typography variant="body2">{t('authEntities.users.firstName')}</Typography>
                                <Typography>{userInfoQuery.data?.first_name}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2">{t('authEntities.users.lastName')}</Typography>
                                <Typography>{userInfoQuery.data?.last_name}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2">{t('authEntities.users.phoneCountryCode')}</Typography>
                                <Typography>{userInfoQuery.data?.phone_country_code}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2">{t('authEntities.users.fmtPhone')}</Typography>
                                <Typography>{userInfoQuery.data?.fmt_phone}</Typography>
                            </div>
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default UserInfoDialog;
