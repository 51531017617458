import { Yup } from '@aseel/common-utils';
import { useQuery } from 'react-query';
import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    PAGE_CODE_OF_CONDUCTS,
    PAGE_PRIVACY_POLICY,
    PAGE_SHARIAA_COMPLIANCE,
    PAGE_TERMS_AND_CONDITIONS,
    PAGE_ABOUT_US,
    PAGE_DISCLOSURE_POLICY,
    PAGE_COUNCIL_OF_MEMBERS,
} from 'Constants/QueriesKeys';
import { Card, CardContent } from '@mui/material';
import { pages, pagesKeys } from './WebContents';
import { useHistory, useParams } from 'react-router-dom';
import EditorSkeleton from './Skeletons/EditorSekelton';
import { Btn, EditorEnAr, ResourcesList, setProgress } from '@aseel/common-ui';
import { handleError } from '@aseel/http';

const queriesKeys = {
    [pagesKeys.privacyPolicy]: PAGE_PRIVACY_POLICY,
    [pagesKeys.shariaaCompliance]: PAGE_SHARIAA_COMPLIANCE,
    [pagesKeys.termsAndConditions]: PAGE_TERMS_AND_CONDITIONS,
    [pagesKeys.codeOfConducts]: PAGE_CODE_OF_CONDUCTS,
    [pagesKeys.aboutUs]: PAGE_ABOUT_US,
    [pagesKeys.disclosurePolicy]: PAGE_DISCLOSURE_POLICY,
    [pagesKeys.councilOfMembers]: PAGE_COUNCIL_OF_MEMBERS,
};

const PAGE_UPDATE_BTN = 'PAGE_UPDATE_BTN';

const schema = Yup.object().shape({
    content: Yup.object().shape({
        en: Yup.string().required(),
        ar: Yup.string().required(),
    }),
});

function PageEditor() {
    const isFormResetted = useRef(false);
    const { t } = useTranslation();
    const { control, reset, handleSubmit, setError } = useForm({
        resolver: yupResolver(schema),
    });
    const history = useHistory();
    const { key } = useParams();

    const query = useQuery(
        queriesKeys[key],
        () => http.get(`/website-contents/${key}`).then((response) => response.data),
        {
            onSuccess: (data) => {
                if (isFormResetted.current === false) {
                    reset({
                        content: {
                            ar: data.content.ar,
                            en: data.content.en,
                        },
                    });
                    isFormResetted.current = true;
                }
            },
        },
    );

    const page = pages().find((page) => page.key === key);

    const handleSubmitTermsAndConditions = async (values) => {
        setProgress(PAGE_UPDATE_BTN, true);
        try {
            await http.put(`/website-contents/${key}`, values);
            history.push('/app/pages');
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(PAGE_UPDATE_BTN, false);
    };

    return query.isFetching ? (
        <EditorSkeleton />
    ) : (
        <form onSubmit={handleSubmit(handleSubmitTermsAndConditions)} className="space-y-5">
            <ResourcesList
                headerProps={{
                    title: `${t('web.page')} ${page.name}`,
                }}
            />
            <div className="px-3.5">
                <Card>
                    <CardContent>
                        <EditorEnAr control={control} arName="content.ar" enName="content.en" />
                        <div className="flex justify-end mt-3.5">
                            <Btn type="submit" name={PAGE_UPDATE_BTN} fullWidth={false}>
                                {t('common.update')}
                            </Btn>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </form>
    );
}

export default PageEditor;
