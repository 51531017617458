import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ANB_EOD_TRANSACTIONS } from 'Constants/QueriesKeys';
import { IconBtnBase, ResourcesList } from '@aseel/common-ui';
import TransactionsTable from './Components/TransactionsTable';
import { Breadcrumbs, Card, Grid, Typography } from '@mui/material';

export default function EODTransactionsPage() {
    const { t } = useTranslation();
    const { eodStatementId } = useParams();
    const [page, setPage] = useState(1);

    const eodStatementTransactionsQuery = useQuery(
        [ANB_EOD_TRANSACTIONS, { page, eodStatementId }],
        () =>
            http
                .get(`/anb/eod-statements/${eodStatementId}/transactions`, { params: { page } })
                .then(({ data }) => data),
        {
            enabled: Boolean(eodStatementId),
        },
    );

    return (
        <Grid xs={12}>
            <ResourcesList
                headerProps={{
                    title: <Typography variant="h4">{t('anb.transactions')}</Typography>,
                    EndSide: (
                        <div className="flex items-center">
                            <IconBtnBase
                                onClick={() => {
                                    eodStatementTransactionsQuery.refetch();
                                }}
                                size="large">
                                <RefreshIcon />
                            </IconBtnBase>
                        </div>
                    ),
                }}
                BeforeHeader={
                    <Breadcrumbs className="mb-4">
                        <Link component={Link} to="/app/eod-statements">
                            {t('anb.eodStatements')}
                        </Link>
                        <Typography color="textPrimary">#{eodStatementId}</Typography>
                    </Breadcrumbs>
                }
                Section={
                    <Card className="mt-8">
                        <TransactionsTable
                            page={page}
                            setPage={setPage}
                            rows={eodStatementTransactionsQuery.data?.data || []}
                            rowCount={eodStatementTransactionsQuery.data?.meta.total}
                            pageSize={eodStatementTransactionsQuery.data?.meta.per_page}
                        />
                    </Card>
                }
            />
        </Grid>
    );
}
