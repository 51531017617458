import React from 'react';
import { DataGrid } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';

export default function TransactionsTable({ setPage, rows, pageSize, rowCount, page }) {
    const { t } = useTranslation();

    const columns = [
        {
            field: 'id',
            headerName: t('anb.id'),
            minWidth: 50,
            editable: false,
        },
        {
            field: 'customer_ref',
            headerName: t('anb.customer_ref'),
            minWidth: 200,
            editable: false,
        },
        {
            field: 'bank_ref',
            headerName: t('anb.bank_ref'),
            minWidth: 200,
            editable: false,
        },
        {
            field: 'description',
            headerName: t('anb.description'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'code',
            headerName: t('anb.code'),
            minWidth: 100,
            editable: false,
        },
        {
            field: 'amount',
            headerName: t('anb.amount'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'value_date',
            headerName: t('anb.valueDate'),
            minWidth: 130,
            editable: false,
        },
    ];

    return (
        <DataGrid
            pagination
            page={page - 1}
            rows={rows}
            columns={columns}
            rowCount={rowCount}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage + 1)}
        />
    );
}
