import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Dialog, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { Yup } from '@aseel/common-utils';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ENQUIRIES } from 'Constants/QueriesKeys';
import { Box } from '@mui/system';
import { Btn, IconBtnBase, InputController, setProgress } from '@aseel/common-ui';
import { handleError } from '@aseel/http';
import { useQueryClient } from 'react-query';

const schema = Yup.object().shape({
    message: Yup.string().required(),
});

const SEND_ENQUIRY_REPLY_BTN = 'SEND_ENQUIRY_REPLY_BTN';

// Component starts here
const EnquiryActionModal = ({ selectedEnquiry, isDrawarOpen, setIsDrawarOpen, enquiryRepliesQuery }) => {
    const { control, handleSubmit, reset, setError } = useForm({ resolver: yupResolver(schema) });
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const [displayMoreDetailsSection, setDisplayMoreDetailsSection] = useState(false);

    const handleSendMessageClick = async (values) => {
        try {
            setProgress(SEND_ENQUIRY_REPLY_BTN, true);
            await http.post(`/enquiries/${selectedEnquiry.id}/replies`, values);
            queryClient.invalidateQueries(ENQUIRIES);
            enquiryRepliesQuery.refetch();
            reset({ message: '' });
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(SEND_ENQUIRY_REPLY_BTN, false);
    };

    const toggleMoreDetailsSection = () => {
        setDisplayMoreDetailsSection(!displayMoreDetailsSection);
    };

    const handleModalClose = () => {
        setIsDrawarOpen(false);
    };

    return (
        <Dialog maxWidth="md" fullWidth open={isDrawarOpen} onClose={handleModalClose}>
            <div>
                {/* Modal Header */}
                <Box
                    sx={{ backgroundColor: 'primary.main' }}
                    className="h-20 p-4 text-3xl flex justify-between items-center">
                    <Typography variant="h5" component="h1" color="primary.contrastText">
                        #{selectedEnquiry?.id}
                    </Typography>
                    <div>
                        <IconBtnBase onClick={toggleMoreDetailsSection} size="large">
                            <VisibilityIcon className="text-white" />
                        </IconBtnBase>
                    </div>
                </Box>

                {/* Modal Body */}
                <div className="p-4">
                    {/* MoreDetails */}
                    {displayMoreDetailsSection && (
                        <div className="mb-6">
                            <div className="flex justify-between flex-wrap">
                                <div className="mb-4 w-1/5">
                                    <b className="text-sm">{t('customerSupport.enquiryId')}</b>
                                    <p className="border-b border-solid border-gray-500">{selectedEnquiry?.id}</p>
                                </div>
                                <div className="mb-4 w-8/12">
                                    <b className="text-sm">{t('customerSupport.reason')}</b>
                                    <p className="border-b border-solid border-gray-500">{selectedEnquiry?.reason}</p>
                                </div>
                            </div>
                            <p>{t('customerSupport.senderInformation')}</p>
                            <div className="flex flex-wrap">
                                <div className="mb-4 md:mb-0 md:w-1/3">
                                    <b className="text-sm">{t('customerSupport.name')}</b>
                                    <p className="border-b border-solid border-gray-500">{selectedEnquiry?.name}</p>
                                </div>
                                <div className="mb-4 md:mb-0 md:w-1/3">
                                    <b className="text-sm">{t('customerSupport.email')}</b>
                                    <p className="border-b border-solid border-gray-500">{selectedEnquiry?.email}</p>
                                </div>
                                <div className="mb-4 md:mb-0 md:w-1/3">
                                    <b className="text-sm">{t('customerSupport.phone')}</b>
                                    <p className="border-b border-solid border-gray-500">
                                        {selectedEnquiry?.phone_number || '---'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Message */}
                    <div className="mt-6">
                        <b className="text-sm">{t('customerSupport.subject')}</b>
                        <p className="border-b border-solid border-gray-500">{selectedEnquiry?.subject}</p>
                    </div>

                    {/* Subject */}
                    <div className="mt-6">
                        <b className="text-sm">{t('customerSupport.message')}</b>
                        <p className="h-24 border-solid border-gray-500 border overflow-auto">
                            {selectedEnquiry?.message}
                        </p>
                    </div>

                    {/* Thread */}
                    <div className="mt-4">
                        <b className="mb-2 block">{t('customerSupport.thread')}</b>
                        {enquiryRepliesQuery.data?.length > 0 &&
                            enquiryRepliesQuery.data.map((reply) => {
                                return (
                                    <div className="p-1.5 border-solid border-gray-500 border mb-2" key={reply?.id}>
                                        <p>{reply?.message}</p>
                                        <p className="text-xs font-semibold text-gray-700">
                                            {reply?.sender?.name}({reply?.sender?.role})
                                        </p>
                                    </div>
                                );
                            })}
                    </div>

                    {/* Thread Loader */}
                    {enquiryRepliesQuery.isFetching && <LinearProgress />}

                    {/* Send Message */}
                    <div className="mt-6">
                        <form onSubmit={handleSubmit(handleSendMessageClick)}>
                            <InputController
                                control={control}
                                name="message"
                                label={t('customerSupport.typeNewReply')}
                                multiline
                                rows={4}
                            />

                            <Btn type="submit" name={SEND_ENQUIRY_REPLY_BTN} className="mt-4">
                                {t('customerSupport.sendMessage')}
                            </Btn>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default EnquiryActionModal;
