import React from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { Card } from '@mui/material';
import { useParams } from 'react-router';
import { DataGrid } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import TableSkeleton from 'Skeletons/TableSkeleton';
import { INVESTOR_INVESTMENTS } from 'Constants/QueriesKeys';

export default function InvestmentsTab() {
    const { investorId } = useParams();
    const { t } = useTranslation();

    const investmentsQuery = useQuery(INVESTOR_INVESTMENTS, () =>
        http.get(`/investors/${investorId}/investments`).then((response) => response.data),
    );

    const investmentsColumns = [
        {
            field: 'id',
            headerName: t('investor.id'),
            minWidth: 120,
        },
        {
            field: 'title',
            headerName: t('investor.investmentsTitle'),
            minWidth: 300,
            flex: 1
        },
        {
            field: 'invested_shares',
            headerName: t('investor.investedShares'),
            minWidth: 200,
        },
        {
            field: 'invested_amount_fmt',
            headerName: t('investor.investedAmounts'),
            minWidth: 200,
        },
    ];

    if (investmentsQuery.isLoading) {
        return (
            <Card>
                <TableSkeleton />
            </Card>
        );
    }

    return (
        <Card>
            <DataGrid rows={investmentsQuery.data?.data} columns={investmentsColumns} />
        </Card>
    );
}
