import './index.css';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { setLocale } from 'yup';
import SignInPage from 'Pages/Auth/SignInPage';
import { useEffect, useState } from 'react';
import { http } from 'Utils/Http/Http';
import App from 'App';
import { AuthContext } from 'Contexts/AuthContext';
import { GuardedRoute } from '@aseel/common-utils';
import AuthGuard from 'Utils/Guards/AuthGuard';
import GuestGuard from 'Utils/Guards/GuestGuard';
import SendResetLinkPage from 'Pages/Auth/SendResetLinkPage';
import ResetPasswordPage from 'Pages/Auth/ResetPasswordPage';

setLocale({
    mixed: {
        required: 'required',
    },
    string: {
        required: 'required',
    },
});

function Entry() {
    const { t } = useTranslation();
    const [isFetchingUserData, setIsFetchingUserData] = useState(true);
    const [auth, setAuth] = useState({
        user: null,
        fetchUserProfile: () => {
            return http
                .get('/auth')
                .then((response) => {
                    setAuth({ ...auth, user: response.data.data });
                })
                .catch(() => {
                    setAuth({ ...auth, user: null });
                });
        },
        refreshCsrf: () => {
            return http.get(`/sanctum/csrf-cookie`, {
                baseURL: process.env.REACT_APP_BASE_URL,
            });
        },
        logout: () => {
            return http
                .post('/api/logout', undefined, {
                    baseURL: process.env.REACT_APP_BASE_URL,
                })
                .then(() => {
                    setAuth({ ...auth, user: null });
                    return auth.refreshCsrf();
                });
        },
    });

    useEffect(() => {
        setIsFetchingUserData(true);
        auth.fetchUserProfile().finally(() => setIsFetchingUserData(false));
        auth.refreshCsrf();
    }, []);

    return (
        <AuthContext.Provider value={auth}>
            {isFetchingUserData ? (
                <div className="h-screen flex justify-center items-center">{t('common.loading')}</div>
            ) : (
                <Switch>
                    <Route exact path="/">
                        <Redirect to="/app/dashboard" />
                    </Route>
                    <GuardedRoute guards={[GuestGuard]} exact path="/sign-in" component={SignInPage} />
                    <Route exact path="/forgot-password" component={SendResetLinkPage} />
                    <Route exact path="/reset-password" component={ResetPasswordPage} />
                    <GuardedRoute guards={[AuthGuard]} path="/app" component={App} />
                </Switch>
            )}
        </AuthContext.Provider>
    );
}

export default Entry;
