import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function InvestorPersonalInformation({ className, investorInfo }) {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.name')}
                </Typography>
                <Typography variant="body1">
                    {investorInfo?.first_name} {investorInfo?.last_name}
                </Typography>
            </div>

            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.email')}
                </Typography>
                <Typography variant="body1">{investorInfo?.email}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.phone')}
                </Typography>
                <Typography variant="body1">{investorInfo?.phone_number ?? '---'}</Typography>
            </div>
            {investorInfo?.profile ? (
                <>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            {t('investor.educationLevel')}
                        </Typography>
                        <Typography variant="body1">{investorInfo.profile.education}</Typography>
                    </div>
                    <div>
                        <Typography variant="subtitle2" color="textSecondary">
                            {t('investor.occupation')}
                        </Typography>
                        <Typography variant="body1">{investorInfo.profile.occupation}</Typography>
                    </div>
                </>
            ) : null}
        </div>
    );
}
