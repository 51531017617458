import React from 'react';
import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

export default function BankAccountsDialogSkeleton() {
    return (
        <div className="space-y-2.5">
            <div className="grid lg:grid-cols-2 gap-4">
                <div>
                    <Typography variant="body2" color="textSecondary">
                        <Skeleton className="w-1/4" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/2" />
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2" color="textSecondary">
                        <Skeleton className="w-1/4" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/2" />
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2" color="textSecondary">
                        <Skeleton className="w-1/4" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/2" />
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2" color="textSecondary">
                        <Skeleton className="w-1/4" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/2" />
                    </Typography>
                </div>
            </div>
            <div className="py-2.5">
                <div>
                    <Typography variant="body2" color="textSecondary">
                        <Skeleton className="w-2/12" />
                    </Typography>
                    <Typography>
                        {Array(3)
                            .fill(0)
                            .map((item, key) => (
                                <div key={key} className="flex justify-between items-center space-y-3">
                                    <Skeleton className="w-4/12" />
                                    <Skeleton width={20} height={35} className="rounded-full" />
                                </div>
                            ))}
                    </Typography>
                </div>
            </div>
            <div>
                <div className="flex divide-y-4 divide-black">
                    <form className="w-full space-y-2.5">
                        <Skeleton height={50} />
                        <div className="flex justify-end">
                            <Skeleton width={80} height={50} />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
