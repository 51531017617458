import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { Skeleton } from '@mui/material';
import { ResourcesList } from '@aseel/common-ui';

export default function EditorSkeleton() {
    return (
        <form className="space-y-5">
            <ResourcesList
                headerProps={{
                    title: <Skeleton className="w-20" />,
                }}
            />
            <div className="px-3.5">
                <Card>
                    <CardContent>
                        <Typography variant="h6">
                            <Skeleton className="w-1/5" />
                        </Typography>

                        <Skeleton variant="rectangular" className="h-96" />

                        <Typography variant="h6">
                            <Skeleton className="w-1/5" />
                        </Typography>

                        <Skeleton variant="rectangular" className="h-96" />
                    </CardContent>
                </Card>
            </div>
        </form>
    );
}
