import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import { IconButton } from '@mui/material';
import CreateOrEditFaqModal from './CreateOrEditFaqModal';
import { FAQS } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { BtnBase, ConfirmationDialog, DataGrid, ResourceListSkeleton, ResourcesList } from '@aseel/common-ui';
import { toast } from 'react-toastify';
import { updateToast } from '@aseel/common-utils';
import { isAxiosError } from '@aseel/http';

// Component goes here
const FaqsListComponent = () => {
    const [page, setPage] = useState(0);

    const faqsQuery = useQuery([FAQS, { page }], () =>
        http
            .get('/faqs', {
                params: { page: page + 1 },
            })
            .then((response) => response.data),
    );
    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [selectedFaqData, setSelectedFaqData] = useState(null);
    const [isFaqCreateEditModalOpen, setIsFaqCreateEditModalOpen] = useState(false);

    const handleDeleteFaq = (faqId) => {
        let toastId = null;
        ConfirmationDialog({
            text: t('faq.deleteWarningMessage', { id: faqId }),
            title: t('faq.deleteWarningTitle', { id: faqId }),
        })
            .then(() => {
                toastId = toast.loading(t('faq.requestToDeleteFaqIsSent', { faq: faqId }));
                return http.delete(`/faqs/${faqId}`);
            })
            .then(() => {
                updateToast(toastId, {
                    type: 'success',
                    render: t('faq.faqHasBeenDeletedSuccessfully', { faq: faqId }),
                });
                return faqsQuery.refetch();
            })
            .catch((error) => {
                if (isAxiosError(error) && toastId) {
                    updateToast(toastId, {
                        type: 'error',
                        render: t('faq.anErrorOccurredWhileDeletingFaq', { faq: faqId }),
                    });
                }
            });
    };

    const columns = [
        {
            field: 'question',
            headerName: t('faq.question'),
            editable: false,
            flex: 1,
        },
        {
            field: 'answer',
            headerName: t('faq.answer'),
            editable: false,
            flex: 1,
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 130,
            editable: false,
            renderCell: (row) => {
                return (
                    <>
                        <IconButton
                            edge="end"
                            onClick={() => {
                                openEditFaqModal(row.row);
                            }}
                            size="large">
                            <EditIcon />
                        </IconButton>
                        <IconButton edge="end" onClick={() => handleDeleteFaq(row.row.id)} size="large">
                            <DeleteForeverRoundedIcon />
                        </IconButton>
                    </>
                );
            },
        },
    ];

    // Handlers
    const openCreateFaqModal = () => {
        setIsEdit(false);
        setIsFaqCreateEditModalOpen(true);
        setSelectedFaqData(null);
    };

    const openEditFaqModal = (faqData) => {
        setIsEdit(true);
        setSelectedFaqData(faqData);
        setIsFaqCreateEditModalOpen(true);
    };

    if (faqsQuery.isLoading) {
        return <ResourceListSkeleton hasEndSide={true} />;
    }

    return (
        <div>
            <ResourcesList
                headerProps={{
                    title: t('faq.faqTitle'),
                    EndSide: (
                        <BtnBase fullWidth={false} onClick={openCreateFaqModal}>
                            {t('common.create')}
                        </BtnBase>
                    ),
                }}
                Section={
                    <Paper className="mt-4">
                        <DataGrid
                            rows={faqsQuery.data?.data || []}
                            columns={columns}
                            page={page}
                            pageSize={faqsQuery.data?.meta.per_page}
                            rowCount={faqsQuery.data?.meta.total}
                            onPageChange={(newPage) => setPage(newPage)}
                            loading={faqsQuery.isFetching}
                            pagination
                            paginationMode="server"
                        />
                    </Paper>
                }
            />

            <CreateOrEditFaqModal
                isOpen={isFaqCreateEditModalOpen}
                isEdit={isEdit}
                selectedFaqData={selectedFaqData}
                faqsQuery={faqsQuery}
                setIsFaqCreateEditModalOpen={setIsFaqCreateEditModalOpen}
            />
        </div>
    );
};

export default FaqsListComponent;
