import React from 'react';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import { Card, CardContent, Typography } from '@mui/material';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import Brightness3RoundedIcon from '@mui/icons-material/Brightness3Rounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import i18n from 'Utils/I18n';
import { NavLink } from 'react-router-dom';
import { ResourcesList } from '@aseel/common-ui';
import AccessibilityRoundedIcon from '@mui/icons-material/AccessibilityRounded';
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';

export const pagesKeys = {
    privacyPolicy: 'privacy-policy',
    codeOfConducts: 'code-of-conducts',
    shariaaCompliance: 'sharia-compliance',
    termsAndConditions: 'terms-and-conditions',
    aboutUs: 'about-us',
    disclosurePolicy: 'disclosure-policy',
    councilOfMembers: 'members-council',
};

export const pages = () => [
    {
        name: i18n.t('web.privacyPolicy'),
        key: pagesKeys.privacyPolicy,
        icon: SecurityRoundedIcon,
    },
    {
        name: i18n.t('web.codeOfConduct'),
        key: pagesKeys.codeOfConducts,
        icon: GavelRoundedIcon,
    },
    {
        name: i18n.t('web.shariaaCompliance'),
        key: pagesKeys.shariaaCompliance,
        icon: Brightness3RoundedIcon,
    },
    {
        name: i18n.t('web.termsAndConditions'),
        key: pagesKeys.termsAndConditions,
        icon: AssignmentRoundedIcon,
    },
    {
        name: i18n.t('web.aboutUs'),
        key: pagesKeys.aboutUs,
        icon: ListAltRoundedIcon,
    },
    {
        name: i18n.t('web.disclosurePolicy'),
        key: pagesKeys.disclosurePolicy,
        icon: AccessibilityRoundedIcon,
    },
    {
        name: i18n.t('web.councilOfMembers'),
        key: pagesKeys.councilOfMembers,
        icon: GroupsRoundedIcon,
    },
];

export default function WebContent() {
    const { t } = useTranslation();
    const { path } = useRouteMatch();

    return (
        <div className="space-y-5">
            <ResourcesList
                headerProps={{
                    title: t('web.pages'),
                }}
            />
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 text-center px-3">
                {pages().map((page) => (
                    <Card key={page.key} component={NavLink} to={`${path}/${page.key}`}>
                        <CardContent>
                            <page.icon fontSize="large" className="text-gray-700" />
                            <Typography className="mt-5">{page.name}</Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </div>
    );
}
