import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import { Chip, IconButton } from '@mui/material';
import CreateOrEditFundModal from './CreateOrEditFundModal';
import { AVAILABLE_FUND_TYPES } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { BtnBase, DataGrid, ResourceListSkeleton, ResourcesList } from '@aseel/common-ui';
import { red, green } from '@mui/material/colors';

const styles = {
    inActive: {
        backgroundColor: red[100],
        color: red[900],
    },
    active: {
        backgroundColor: green[100],
        color: green[900],
    },
};

// Component goes here
const FundTypesListComponent = (props) => {
    const availableFundsQuery = useQuery(AVAILABLE_FUND_TYPES, () =>
        http.get('/property-types').then(({ data }) => data.data),
    );

    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [selectedFundData, setSelectedFundData] = useState({});
    const [isFundCreateEditModalOpen, setIsFundCreateEditModalOpen] = useState(false);

    const columns = [
        {
            field: 'name',
            headerName: t('common.name'),
            editable: false,
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('common.status'),
            minWidth: 130,
            editable: false,
            renderCell: (row) => (
                <div>
                    <Chip
                        size="small"
                        label={row.row.is_active ? t('fundTypes.active') : t('fundTypes.inActive')}
                        sx={row.row.is_active ? styles.active : styles.inActive}
                    />
                </div>
            ),
        },
        {
            field: 'action',
            headerName: t('common.actions'),
            minWidth: 130,
            editable: false,
            renderCell: (row) => {
                return (
                    <IconButton
                        edge="end"
                        onClick={() => {
                            openEditFundModal(row.row);
                        }}
                        size="large">
                        <EditIcon />
                    </IconButton>
                );
            },
        },
    ];

    // Handlers
    const openCreateFundModal = () => {
        setIsEdit(false);
        openCreateEditModal(true);
    };

    const openEditFundModal = (fundData) => {
        setIsEdit(true);
        setSelectedFundData(fundData);
        openCreateEditModal(true);
    };

    const openCreateEditModal = () => {
        setIsFundCreateEditModalOpen(true);
    };

    if (availableFundsQuery.isLoading) {
        return <ResourceListSkeleton hasEndSide={true} />;
    }

    return (
        <div>
            <ResourcesList
                headerProps={{
                    title: t('fundTypes.fundTypesPageHeader'),
                    EndSide: (
                        <BtnBase fullWidth={false} onClick={openCreateFundModal}>
                            {t('common.create')}
                        </BtnBase>
                    ),
                }}
                Section={
                    <Paper className="mt-4">
                        <DataGrid
                            loading={availableFundsQuery.isFetching}
                            rows={availableFundsQuery.data || []}
                            columns={columns}
                        />
                    </Paper>
                }
            />

            <CreateOrEditFundModal
                isFundCreateEditModalOpen={isFundCreateEditModalOpen}
                isEdit={isEdit}
                selectedFundData={selectedFundData}
                availableFundsQuery={availableFundsQuery}
                setIsFundCreateEditModalOpen={setIsFundCreateEditModalOpen}
            />
        </div>
    );
};

export default FundTypesListComponent;
