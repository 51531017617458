import React from 'react';
import FundInformation from '@aseel/common-components/src/Funds/FundInformation/FundInformation';
import FundInformationSkeleton from '@aseel/common-components/src/Skeletons/Funds/FundInformationSkeleton';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router-dom';
import { FUNDS } from 'Constants/QueriesKeys';

export default function FundsInformation() {
    const { fundId } = useParams();
    const showInfoQuery = useQuery([FUNDS, { id: fundId }], () =>
        http(`/listings/${fundId}`).then((response) => response.data.data),
    );

    if (showInfoQuery.isLoading) {
        return <FundInformationSkeleton informationContainerClassName="grid grid-cols-3 gap-10 p-5" />;
    }

    return (
        <FundInformation
            statusesContainerClassName="flex space-x-5 "
            informationContainerClassName="grid md:grid-cols-3 gap-10 p-5"
            data={showInfoQuery.data}
        />
    );
}
