import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, LinearProgress, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import NoLogsFound from './NoLogsFound';
import { toast } from 'react-toastify';
import { ConfirmationDialog, Empty, ResourcesList } from '@aseel/common-ui';
import { Box } from '@mui/system';

const styles = {
    textContainer: {
        whiteSpace: 'pre-line',
    },
};

const Log = ({ title, url, queryKey }) => {
    const { t } = useTranslation();

    const logsQuery = useQuery(queryKey, () => http.get(url).then(({ data }) => data.data), {
        refetchOnWindowFocus: false,
    });

    // Delete Logs
    const handleLogsDeletion = async () => {
        try {
            await http.delete(url);
            logsQuery.refetch();
            toast.success(t('logs.deleteSuccessMessage'));
        } catch (e) {
            toast.error(t('logs.deleteErrorMessage'));
        }
    };

    const openDeleteConfirmationDialog = () => {
        ConfirmationDialog({
            title: t('logs.pleaseConfirm'),
            text: t('logs.deleteConfirmationMessage'),
        })
            .then(() => {
                handleLogsDeletion();
            })
            .catch((err) => {});
    };

    return (
        <ResourcesList
            headerProps={{
                title: title,
                EndSide: (
                    <div>
                        <IconButton onClick={logsQuery.refetch} size="large">
                            <RefreshIcon />
                        </IconButton>
                        <IconButton onClick={openDeleteConfirmationDialog} size="large">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ),
            }}
            Section={
                <div className="mt-4">
                    {logsQuery.isFetching && <LinearProgress />}

                    <Card>
                        <CardContent>
                            {logsQuery?.data?.logs ? (
                                <Box sx={styles.textContainer}>{logsQuery.data.logs}</Box>
                            ) : (
                                <Empty>
                                    <NoLogsFound />
                                </Empty>
                            )}
                        </CardContent>
                    </Card>
                </div>
            }
        />
    );
};

export default Log;
