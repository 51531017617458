import React from 'react';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactsIcon from '@mui/icons-material/Contacts';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import WarningIcon from '@mui/icons-material/Warning';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MoneyIcon from '@mui/icons-material/Money';
import { useTranslation } from 'react-i18next';
import DashboardSectionLayout from '../Common/DashboardSectionLayout';
import { useQuery } from 'react-query';
import { DASHBOARD_OVERVIEW } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import OverviewSkeleton from '@aseel/common-components/src/Skeletons/Dashboard/OverviewSkeleton';
import OverviewCard from '@aseel/common-components/src/Dashboard/Overview/OverviewCard';

const styles = {
    iconStyle: {
        fontSize: '3rem',
    },
    danger: {
        color: '#ff3838',
    },
    success: {
        color: '#07f702',
    },
};

const Overview = () => {
    const { t } = useTranslation();

    const dashboardQuery = useQuery(DASHBOARD_OVERVIEW, () =>
        http.get('/dashboard/overview').then(({ data }) => data.data),
    );

    const cardsData = [
        {
            value: dashboardQuery.data?.registrations_count,
            identifier: t('dashboard.registrations'),
            icon: <AccountBoxIcon sx={styles.iconStyle} />,
        },
        {
            value: dashboardQuery.data?.prospective_investors_count,
            identifier: t('dashboard.prospectiveInvestors'),
            icon: <ContactsIcon sx={styles.iconStyle} />,
        },
        {
            value: dashboardQuery.data?.investors_who_invested_count,
            identifier: t('dashboard.investors'),
            icon: <AssignmentIndIcon sx={styles.iconStyle} />,
        },
        {
            value: dashboardQuery.data?.enabled_investors_count,
            identifier: t('dashboard.enabled'),
            icon: <CheckIcon sx={{ ...styles.iconStyle, ...styles.success }} />,
        },
        {
            value: dashboardQuery.data?.disabled_investors_count,
            identifier: t('dashboard.disabled'),
            icon: <CloseIcon sx={{ ...styles.iconStyle, ...styles.danger }} />,
        },
        {
            value: dashboardQuery.data?.aml_flags_count,
            identifier: t('dashboard.amlFlags'),
            icon: <WarningIcon sx={{ ...styles.iconStyle, ...styles.danger }} />,
        },
        {
            value: dashboardQuery.data?.listings_count,
            identifier: t('dashboard.funds'),
            icon: <HomeWorkIcon sx={styles.iconStyle} />,
        },
        {
            value: dashboardQuery.data?.total_invested,
            identifier: t('dashboard.totalInvested'),
            icon: <LocalAtmIcon sx={styles.iconStyle} />,
        },
        {
            value: dashboardQuery.data?.total_aum,
            identifier: t('dashboard.totalAUM'),
            icon: <MoneyIcon sx={styles.iconStyle} />,
        },
    ];

    return (
        <DashboardSectionLayout
            sectionTitle={t('dashboard.overview')}
            isLoading={false}
            sectionContent={
                dashboardQuery.isLoading ? (
                    <OverviewSkeleton count={9} />
                ) : (
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {cardsData.map((data) => {
                            return <OverviewCard data={data} />;
                        })}
                    </div>
                )
            }
        />
    );
};

export default Overview;
