import clsx from 'clsx';
import React from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { green, red } from '@mui/material/colors';
import CreateEditUserDialog from './Components/CreateEditUserDialog';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Breadcrumbs, Card, IconButton, Link, Typography } from '@mui/material';
import { useState } from 'react';
import { AUTHORIZED_ENTITY_USERS } from 'Constants/QueriesKeys';
import { NavLink, useParams } from 'react-router-dom';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import UserInfoDialog from './Components/UserInfoDialog';
import { ResourceListSkeleton, DataGrid, ResourcesList, BtnBase } from '@aseel/common-ui';
import { Box } from '@mui/system';

export const userListDidJoin = {};

const styles = {
    joined: {
        backgroundColor: green[100],
        color: green[900],
    },
    notJoined: {
        backgroundColor: red[100],
        color: red[900],
    },
};

function UsersPage() {
    const { t } = useTranslation();
    const { authorizedEntityId } = useParams();
    const [isEdit, setIsEdit] = useState(false);
    const [page, setPage] = useState(0);
    const [isUserDialogInfoOpen, setIsUserDialogInfoOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isUsersDialogOpen, setIsUsersDialogOpen] = useState(false);

    const usersQuery = useQuery([AUTHORIZED_ENTITY_USERS, { id: authorizedEntityId, page }], () =>
        http
            .get(`/authorized-entities/${authorizedEntityId}/users`, {
                params: {
                    page: page + 1,
                },
            })
            .then((response) => response.data),
    );

    const authorizedEntityQuery = useQuery([AUTHORIZED_ENTITY_USERS, { id: authorizedEntityId }], () =>
        http.get(`/authorized-entities/${authorizedEntityId}`).then((response) => response.data.data),
    );

    if (usersQuery.isLoading || authorizedEntityQuery.isLoading) {
        return <ResourceListSkeleton hasEndSide={true} hasBreadcrumbs={true} />;
    }

    const usersListColumns = [
        {
            field: 'id',
            headerName: t('authEntities.users.id'),
        },
        {
            field: 'full_name',
            headerName: t('authEntities.users.fullName'),
            flex: 1,
        },
        {
            field: 'email',
            headerName: t('authEntities.users.email'),
            flex: 1,
        },
        {
            field: 'did_join',
            headerName: t('authEntities.users.didJoin'),
            flex: 1,
            renderCell: (row) => (
                <Box
                    className="rounded-full px-3 text-black-600 leading-8"
                    sx={row.row.did_join ? styles.joined : styles.notJoined}>
                    {row.row.did_join ? t('authEntities.users.joined') : t('authEntities.users.notJoined')}
                </Box>
            ),
        },
        {
            field: 'actions',
            headerName: t('authEntities.users.actions'),
            flex: 1,
            renderCell: (row) => (
                <div>
                    <IconButton onClick={() => handleShowUsersInfo(row.row)} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditUserInfo(row.row)} size="large">
                        <EditRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    const handleShowUsersInfo = (row) => {
        setSelectedUser(row);
        setIsUserDialogInfoOpen(true);
    };

    const handleInviteUser = () => {
        setIsEdit(false);
        setIsUsersDialogOpen(true);
    };

    const handleEditUserInfo = (row) => {
        setSelectedUser(row);
        setIsEdit(true);
        setIsUsersDialogOpen(true);
    };

    return (
        <>
            <ResourcesList
                BeforeHeader={
                    <Breadcrumbs className="mb-4">
                        <Link component={NavLink} to="/app/authorized-entities">
                            {t('authEntities.authorizedEntities')}
                        </Link>
                        <Link component={NavLink} to={`/app/authorized-entities/${authorizedEntityId}`}>
                            #{authorizedEntityId} {authorizedEntityQuery.data?.name}
                        </Link>
                        <Typography color="textPrimary">{t('authEntities.users.accounts')}</Typography>
                    </Breadcrumbs>
                }
                headerProps={{
                    title: t('authEntities.users.accounts'),
                    EndSide: (
                        <BtnBase fullWidth={false} onClick={handleInviteUser}>
                            {t('authEntities.users.invite')}
                        </BtnBase>
                    ),
                }}
                Section={
                    <div className="mt-4">
                        <Card>
                            <DataGrid
                                columns={usersListColumns}
                                rows={usersQuery.data.data}
                                page={page}
                                pageSize={usersQuery.data?.meta.per_page}
                                rowCount={usersQuery.data?.meta.total}
                                onPageChange={(newPage) => setPage(newPage)}
                                paginationMode="server"
                                pagination
                            />
                        </Card>
                    </div>
                }
            />

            <UserInfoDialog
                isOpen={isUserDialogInfoOpen}
                setIsUserDialogInfoOpen={setIsUserDialogInfoOpen}
                authorizedEntityId={authorizedEntityId}
                selectedUser={selectedUser}
            />

            <CreateEditUserDialog
                isOpen={isUsersDialogOpen}
                setIsOpen={setIsUsersDialogOpen}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                usersQuery={usersQuery}
                selectedUser={selectedUser}
                authorizedEntityId={authorizedEntityId}
            />
        </>
    );
}

export default UsersPage;
