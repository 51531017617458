import React from 'react';
import { useTranslation } from 'react-i18next';
import { green, red } from '@mui/material/colors';
import HistoryIcon from '@mui/icons-material/History';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Paper, Tooltip } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ApprovalStatusChip } from '@aseel/common-components';
import { approvalStatusModalTypes } from '@aseel/common-components';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { FlexDataGrid, ResourcesList, ResourceListSkeleton } from '@aseel/common-ui';

const styles = {
    notCompleted: {
        color: red[900],
    },
    completed: {
        color: green[900],
    },
};

const InvestorPaginatedTable = ({
    page,
    setPage,
    handleAmlOpen,
    investorQuery,
    handleBalanceOpen,
    onAmlReassessment,
    onUpdateStatus,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            field: 'id',
            headerName: t('investor.id'),
            editable: false,
        },
        {
            field: 'name',
            headerName: t('investor.name'),
            flex: 1,
            minWidth: 180,
            editable: false,
        },
        {
            field: 'status',
            headerName: t('investor.status'),
            minWidth: 150,
            editable: false,
            renderCell: (row) => (
                <ApprovalStatusChip
                    label={row.row.status?.description}
                    status={row.row.status?.value}
                    modalType={approvalStatusModalTypes.investor}
                />
            ),
        },
        {
            field: 'KYC',
            headerName: t('investor.kyc'),
            minWidth: 140,
            editable: false,
            renderCell: (row) =>
                row.row?.is_kyc_completed ? (
                    <CheckRoundedIcon sx={styles.completed} />
                ) : (
                    <ClearRoundedIcon sx={styles.notCompleted} />
                ),
        },
        {
            field: 'action',
            headerName: t('investor.actions'),
            minWidth: 250,
            flex: 1,
            editable: false,
            renderCell: (row) => {
                return (
                    <div className="flex">
                        <Tooltip placement="top" title={t('investor.view')}>
                            <IconButton
                                onClick={() => {
                                    onUpdateStatus(row.row);
                                }}
                                size="large">
                                <VisibilityIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title={t('investor.amlHistory')}>
                            <IconButton
                                onClick={() => {
                                    handleAmlOpen(row.row);
                                }}
                                size="large">
                                <HistoryIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title={t('investor.amlReassessment')}>
                            <IconButton
                                onClick={() => {
                                    onAmlReassessment(row.row);
                                }}
                                size="large">
                                <AssignmentTurnedInIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    if (investorQuery.isLoading) {
        return <ResourceListSkeleton />;
    }

    return (
        <>
            <ResourcesList
                headerProps={{
                    title: t('investor.title'),
                    EndSide: (
                        <IconButton onClick={investorQuery.refetch} size="large">
                            <RefreshIcon />
                        </IconButton>
                    ),
                }}
                Section={
                    <div className="mt-8">
                        <div>
                            <Paper>
                                <FlexDataGrid
                                    pagination
                                    rowCount={investorQuery.data?.meta.total}
                                    paginationMode="server"
                                    onPageChange={(newPage) => setPage(newPage + 1)}
                                    rows={investorQuery.data.data}
                                    columns={columns}
                                    pageSize={investorQuery.data?.meta.per_page}
                                    page={page - 1}
                                />
                            </Paper>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export default InvestorPaginatedTable;
