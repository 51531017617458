import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router';
import { url } from '@aseel/common-utils';
import { DataGrid } from '@aseel/common-ui';
import { useTranslation } from 'react-i18next';
import TableSkeleton from 'Skeletons/TableSkeleton';
import { ExportReportDialog } from '@aseel/common-components';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { Card, IconButton, Skeleton, Typography } from '@mui/material';
import { INVESTOR_BALANCE, INVESTOR_TRANSACTIONS } from 'Constants/QueriesKeys';

export default function BalanceAndTransactionsTab() {
    const { t } = useTranslation();
    const { investorId } = useParams();
    const [page, setPage] = useState(1);
    const [isExportReportsDialogOpen, setIsExportReportsDialogOpen] = useState(false);

    const balanceQuery = useQuery(
        [INVESTOR_BALANCE, { id: investorId }],
        () => http.get(`/investors/${investorId}/balance-inquiry`).then(({ data }) => data),
        {
            enabled: Boolean(investorId),
        },
    );

    const transactionQuery = useQuery(
        [INVESTOR_TRANSACTIONS, { id: investorId, page }],
        () => http.get(`/investors/${investorId}/transactions`, { params: { page } }).then(({ data }) => data),
        {
            enabled: Boolean(investorId),
        },
    );

    const columns = [
        {
            field: 'created_at',
            headerName: t('investor.transaction.createdAt'),
            minWidth: 150,
        },
        {
            field: 'description',
            headerName: t('investor.transaction.description'),
            minWidth: 250,
            flex: 1,
        },
        {
            field: 'credit',
            headerName: t('investor.transaction.credit'),
            minWidth: 150,
        },
        {
            field: 'debit',
            headerName: t('investor.transaction.debit'),
            minWidth: 150,
        },
    ];

    const reportDownloadUrl = (formData) =>
        url(`api/admin/investors/${investorId}/transactions/export`, { ...formData });

    return (
        <div>
            <ExportReportDialog
                urlGenerator={reportDownloadUrl}
                isOpen={isExportReportsDialogOpen}
                isExportReportsDialogOpen={isExportReportsDialogOpen}
                setIsExportReportsDialogOpen={setIsExportReportsDialogOpen}
            />
            <Card className="py-2.5 pl-2.5 mb-5">
                <Typography variant="subtitle1" textAlign="center" component="h1" color="textSecondary">
                    {t('investor.balance')}
                </Typography>
                {balanceQuery.isLoading ? (
                    <Skeleton className="w-24" />
                ) : (
                    <Typography textAlign="center" variant="h6" component="p">
                        SAR {balanceQuery.data?.balance}
                    </Typography>
                )}
            </Card>

            <div>
                <div className="flex justify-between items-center">
                    <Typography variant="subtitle1" component="h1" color="textSecondary">
                        {t('investor.transactions')}
                    </Typography>
                    <IconButton onClick={() => setIsExportReportsDialogOpen(!isExportReportsDialogOpen)}>
                        <DownloadRoundedIcon />
                    </IconButton>
                </div>
                <div>
                    {transactionQuery.isLoading || transactionQuery.data === undefined ? (
                        <Card>
                            <TableSkeleton />
                        </Card>
                    ) : (
                        <Card>
                            <DataGrid
                                pagination
                                rowCount={transactionQuery.data?.meta.total}
                                paginationMode="server"
                                onPageChange={(page) => setPage(page + 1)}
                                loading={transactionQuery.isLoading}
                                rows={transactionQuery.data.data}
                                columns={columns}
                                pageSize={transactionQuery.data?.meta.per_page}
                                page={page - 1}
                            />
                        </Card>
                    )}
                </div>
            </div>
        </div>
    );
}
