import React, { useState } from 'react';
import { DataGrid, BtnBase, ResourceListSkeleton, ResourcesList } from '@aseel/common-ui';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useParams } from 'react-router';
import { FUND_VALUATIONS } from 'Constants/QueriesKeys';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs, Card, IconButton, Typography, Link } from '@mui/material';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ValuationsInfoDialog from './Components/ValuationsInfoDialog';
import CreateEditValuationsDialog from './Components/CreateEditValuationsDialog';
import { NavLink } from 'react-router-dom';

export default function Valuations({ getValuationsRequest, getValuationRequest }) {
    const { fundId } = useParams();

    const [createEditDialogState, setCreateEditDialogState] = useState({
        isOpen: false,
        data: null,
        isEdit: false,
    });

    const [infoDialogState, setInfoDialogState] = useState({
        isOpen: false,
        data: null,
    });

    const [page, setPage] = useState(0);
    const { t } = useTranslation();

    const valuationsQuery = useQuery([FUND_VALUATIONS, { fundId, page }], getValuationsRequest);

    if (valuationsQuery.isLoading) {
        return <ResourceListSkeleton hasBreadcrumbs={true} />;
    }

    const handleCloseInfoDialog = () => {
        setInfoDialogState({
            ...infoDialogState,
            isOpen: false,
        });
    };

    const handleOpenInfoDialog = (row) => {
        setInfoDialogState({
            data: row,
            isOpen: true,
        });
    };

    const handleCloseCreateEditDialog = () => {
        setCreateEditDialogState({
            ...createEditDialogState,
            isOpen: false,
        });
    };

    const handleOpenCreateEditDialog = (row = null) => {
        setCreateEditDialogState({
            data: row,
            isOpen: true,
            isEdit: Boolean(row),
        });
    };

    const valuationsColumns = [
        {
            field: 'date',
            headerName: t('funds.valuations.date'),
            editable: false,
            minWidth: 150,
            flex: 0.5,
        },
        {
            field: 'value_fmt',
            headerName: t('funds.valuations.value'),
            editable: false,
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'actions',
            headerName: t('funds.reports.action'),
            editable: false,
            minWidth: 150,
            renderCell: (row) => (
                <div>
                    <IconButton onClick={() => handleOpenInfoDialog(row.row)} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenCreateEditDialog(row.row)} size="large">
                        <EditRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    return (
        <>
            <CreateEditValuationsDialog
                onClose={handleCloseCreateEditDialog}
                fundId={fundId}
                valuationsQuery={valuationsQuery}
                getValuationRequest={getValuationRequest}
                {...createEditDialogState}
            />

            <ValuationsInfoDialog onClose={handleCloseInfoDialog} {...infoDialogState} />

            <ResourcesList
                BeforeHeader={
                    <Breadcrumbs className="mb-4">
                        <Link component={NavLink} to="/app/funds">
                            {t('funds.funds')}
                        </Link>
                        <Typography color="textPrimary">#{fundId}</Typography>
                    </Breadcrumbs>
                }
                headerProps={{
                    title: t('funds.valuations.valuations'),
                    EndSide: (
                        <div className="flex">
                            <BtnBase fullWidth={false} onClick={handleOpenCreateEditDialog}>
                                {t('common.create')}
                            </BtnBase>
                        </div>
                    ),
                }}
                Section={
                    <Card className="mt-4">
                        <DataGrid
                            rows={valuationsQuery.data?.data || []}
                            columns={valuationsColumns}
                            page={page}
                            pageSize={valuationsQuery.data?.meta?.per_page}
                            rowCount={valuationsQuery.data?.meta?.total}
                            onPageChange={(newPage) => setPage(newPage)}
                            paginationMode="server"
                        />
                    </Card>
                }
            />
        </>
    );
}
