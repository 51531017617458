import React from 'react';
import { DASHBOARD_FUNDS } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import FundsCard from './FundCard';
import { useTranslation } from 'react-i18next';
import DashboardSectionLayout from '../Common/DashboardSectionLayout';
import FundCardSkeleton from '../../Skeletons/FundCardSkeleton';

const Funds = () => {
    const { t } = useTranslation();

    const fundsQuery = useQuery(DASHBOARD_FUNDS, () => http.get(`/dashboard/listings`).then(({ data }) => data.data));

    return (
        <div>
            <DashboardSectionLayout
                sectionTitle={t('dashboard.funds')}
                isLoading={fundsQuery.isFetching}
                sectionContent={
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {fundsQuery.isLoading ? (
                            Array(8).fill(null).map(() => {
                                return <FundCardSkeleton />;
                            })
                        ) : (
                            fundsQuery.data?.map((data) => {
                                return <FundsCard data={data} />;
                            })
                        )}
                    </div>
                }
            />
        </div>
    );
};

export default Funds;
