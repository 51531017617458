import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import EnquiriesPage from 'Pages/CustomerSupport/EnquiriesPage';
import InvestorsPage from 'Pages/Investors/InvestorsPage';
import FundTypesPage from 'Pages/FundTypes/FundTypesPage';
import WebContents from 'Pages/WebContents/WebContents';
import PageEditor from 'Pages/WebContents/PageEditor';
import EODTransactionsPage from 'Pages/ANB/EODTransactionsPage';
import BankAccountsPage from 'Pages/BankAccounts/BankAccountsPage';
import AuthorizedEntitiesPage from 'Pages/AuthorizedEntities/AuthorizedEntitiesPage';
import AuthorizedEntityPage from 'Pages/AuthorizedEntities/AuthorizedEntityPage';
import UsersPage from 'Pages/AuthorizedEntities/UsersPage';
import FundsPage from 'Pages/Funds/FundsPage';
import FundCreateEditPage from 'Pages/Funds/FundCreateEditPage';
import FundIBanAndInvestorsPage from 'Pages/Funds/FundIBanAndInvestors/FundIBanAndInvestorsPage';
import FundReportsPage from 'Pages/Funds/Reports/FundReportsPage';
import FaqsPage from 'Pages/FAQs/FaqsPage';
import { AppLayout } from '@aseel/common-ui';
import { NavLinks } from 'Constants/NavLinks';
import ContactUsReasonsPage from 'Pages/ContactUsReasons/ContactUsReasonsPage';
import FundDiscussionPage from 'Pages/Funds/Discussions/FundDiscussionPage';
import SystemLogsPage from 'Pages/SystemLogs/SystemLogsPage';
import WorkerLogsPage from 'Pages/WorkerLogs/WorkerLogsPage';
import DashboardPage from 'Pages/Dashboard/DashboardPage';
import FundsInformation from 'Pages/Funds/FundsInformation/FundsInformation';
import FundRevisionsPage from 'Pages/Funds/FundRevisions/FundRevisionsPage';
import PayoutDetailsPage from 'Pages/Funds/Payouts/Components/PayoutDetailsPage';
import FundPayoutsPage from 'Pages/Funds/Payouts/FundPayoutsPage';
import ValuationsPage from 'Pages/Funds/Valuations/ValuationsPage';
import InvestorInfoPage from 'Pages/Investors/InvestorInfoPage';
import CreateEditReportPage from 'Pages/Funds/Reports/Components/CreateEditReportPage';
import useAuth from 'Hooks/UseAuth';
import ANBPage from 'Pages/ANB/ANBPage';
import ProfessionalRequests from 'Pages/ProfessionalRequests/ProfessionalRequestsPage';
import ShowProfessionalRequestPage from 'Pages/ProfessionalRequests/ShowProfessionalRequestPage';

export default function App() {
    let { path } = useRouteMatch();
    const auth = useAuth();

    return (
        <AppLayout links={NavLinks} auth={auth}>
            <Switch>
                <Route exact path={`${path}/dashboard`} component={DashboardPage} />
                <Route exact path={`${path}/enquiries`} component={EnquiriesPage} />
                <Route exact path={`${path}/investors`} component={InvestorsPage} />
                <Route exact path={`${path}/investors/:investorId`} component={InvestorInfoPage} />
                <Route exact path={`${path}/fund-types`} component={FundTypesPage} />
                <Route exact path={`${path}/pages`} component={WebContents} />
                <Route exact path={`${path}/pages/:key`} component={PageEditor} />
                <Route exact path={`${path}/anb`} component={ANBPage} />
                <Route exact path={`${path}/anb/:eodStatementId/transactions`} component={EODTransactionsPage} />
                <Route exact path={`${path}/bank-accounts`} component={BankAccountsPage} />
                <Route exact path={`${path}/authorized-entities`} component={AuthorizedEntitiesPage} />
                <Route exact path={`${path}/authorized-entities/:id`} component={AuthorizedEntityPage} />
                <Route exact path={`${path}/authorized-entities/:authorizedEntityId/users`} component={UsersPage} />

                <Route exact path={`${path}/funds`} component={FundsPage} />
                <Route exact path={`${path}/funds/:fundId`} component={FundCreateEditPage} />
                <Route exact path={`${path}/funds/:fundId/v-accounts`} component={FundIBanAndInvestorsPage} />
                <Route exact path={`${path}/funds/:fundId/show`} component={FundsInformation} />
                <Route exact path={`${path}/funds/:fundId/payouts`} component={FundPayoutsPage} />
                <Route exact path={`${path}/funds/:fundId/discussions`} component={FundDiscussionPage} />
                <Route exact path={`${path}/funds/:fundId/reports`} component={FundReportsPage} />
                <Route exact path={`${path}/funds/:fundId/reports/:reportId`} component={CreateEditReportPage} />
                <Route exact path={`${path}/funds/:fundId/revisions`} component={FundRevisionsPage} />
                <Route exact path={`${path}/funds/:fundId/valuations`} component={ValuationsPage} />
                <Route exact path={`${path}/funds/:fundId/show`} component={FundsInformation} />
                <Route exact path={`${path}/funds/:fundId/payouts`} component={FundPayoutsPage} />
                <Route exact path={`${path}/funds/:fundId/payouts/:payoutId`} component={PayoutDetailsPage} />
                <Route exact path={`${path}/faqs`} component={FaqsPage} />
                <Route exact path={`${path}/contact-reasons`} component={ContactUsReasonsPage} />
                <Route exact path={`${path}/logs`} component={SystemLogsPage} />
                <Route exact path={`${path}/worker-logs`} component={WorkerLogsPage} />
                <Route exact path={`${path}/professional-requests`} component={ProfessionalRequests} />
                <Route
                    exact
                    path={`${path}/professional-requests/:professionalRequestId`}
                    component={ShowProfessionalRequestPage}
                />
            </Switch>
        </AppLayout>
    );
}
