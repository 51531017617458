import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';




const NoLogsFound = () => {
    const { t } = useTranslation();

    return (
        <div className="text-center">
            <Typography color="textSecondary">{t('logs.noLogsFound')}</Typography>
        </div>
    );
};

export default NoLogsFound;
