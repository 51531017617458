import React from 'react';
import { http } from 'Utils/Http/Http';
import ProjectLogoDark from '@aseel/common-assets/assets/aseel-logo-dark.png';
import SendResetLink from '@aseel/common-components/src/Auth/SendResetLink';
import { Card, CardContent, Typography, Divider, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

const sendResetLink = (values) =>
    http.post('/api/forgot-password', values, {
        baseURL: process.env.REACT_APP_BASE_URL,
    });

export default function SendResetLinkPage() {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col items-center justify-center min-h-screen px-2 bg-gray-100 md:px-0">
            <img className="w-auto mx-auto mb-5 h-14" src={ProjectLogoDark} alt="" />
            <Container className="max-w-md">
                <Card>
                    <div className="py-4 px-3.5">
                        <Typography variant="h5">{t('password.resetPassword')}</Typography>
                    </div>
                    <Divider />
                    <CardContent>
                        <SendResetLink
                            sendResetLink={sendResetLink}
                            buttonClassName="flex justify-end mt-5"
                            fullWidth={false}
                        />
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
}
