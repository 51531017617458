import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FAQS } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { Btn, BtnBase, FormSkeleton, InputController, setProgress } from '@aseel/common-ui';
import { Yup } from '@aseel/common-utils';
import { http } from 'Utils/Http/Http';
import { handleError } from '@aseel/http';

const schema = Yup.object().shape({
    question_en: Yup.string().required(),
    answer_en: Yup.string().required(),
    question_ar: Yup.string().required(),
    answer_ar: Yup.string().required(),
});

const CREATE_UPDATE_FAQ_BTN = 'CREATE_UPDATE_FAQ_BTN';

// Component goes here
const CreateOrEditFaqModal = ({ isOpen, isEdit, selectedFaqData, faqsQuery, setIsFaqCreateEditModalOpen }) => {
    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(schema),
    });
    const { t } = useTranslation();
    const faqDetailsQuery = useQuery(
        [FAQS, { id: selectedFaqData?.id }],
        () => http.get(`/faqs/${selectedFaqData?.id}`).then((response) => response.data.data),
        {
            enabled: Boolean(selectedFaqData?.id),
            onSuccess: (data) => {
                reset({ ...data });
            },
        },
    );

    // Handlers
    const closeCreateEditModal = () => {
        setIsFaqCreateEditModalOpen(false);
        reset({});
    };
    // Final Form Submission
    const handleFormSubmission = async (formData) => {
        setProgress(CREATE_UPDATE_FAQ_BTN, true);
        try {
            await http.request({
                method: isEdit ? 'PUT' : 'POST',
                url: isEdit ? `/faqs/${selectedFaqData.id}` : '/faqs',
                data: formData,
            });
            faqsQuery.refetch();
            closeCreateEditModal();
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(CREATE_UPDATE_FAQ_BTN, false);
    };

    return (
        <Dialog open={isOpen} fullWidth maxWidth="md" onClose={closeCreateEditModal}>
            <DialogContent>
                {faqDetailsQuery.isLoading ? (
                    <FormSkeleton inputsCount={2} />
                ) : (
                    <>
                        <Typography variant="h6" component="h1" className="mb-3">
                            {isEdit ? t('faq.editFAQ') : t('faq.createFAQ')}
                        </Typography>

                        <form onSubmit={handleSubmit(handleFormSubmission)} className="space-y-3">
                            <InputController control={control} name="question_en" label={t('faq.question_en')} />
                            <InputController
                                control={control}
                                name="answer_en"
                                label={t('faq.answer_en')}
                                multiline={true}
                                rows="3"
                            />
                            <InputController control={control} name="question_ar" label={t('faq.question_ar')} />
                            <InputController
                                control={control}
                                name="answer_ar"
                                label={t('faq.answer_ar')}
                                multiline={true}
                                rows="3"
                            />

                            <DialogActions className="flex justify-end">
                                <BtnBase variant="text" onClick={closeCreateEditModal} fullWidth={false}>
                                    {t('common.close')}
                                </BtnBase>
                                <Btn type="submit" name={CREATE_UPDATE_FAQ_BTN} fullWidth={false}>
                                    {isEdit ? t('common.update') : t('common.create')}
                                </Btn>
                            </DialogActions>
                        </form>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default CreateOrEditFaqModal;
