import React from 'react';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DataGrid, ResourcesList } from '@aseel/common-ui';

const StatusCell = ({ statusId = 0, status = '' }) => {
    let statusColorClass = '';
    switch (statusId) {
        case 1:
            statusColorClass = 'bg-yellow-100';
            break;
        case 2:
            statusColorClass = 'bg-green-100';
            break;
        case 3:
            statusColorClass = 'bg-red-100';
            break;
        case 4:
            statusColorClass = 'bg-green-100';
            break;
        default:
            statusColorClass = 'bg-white-100';
    }

    return (
        <div>
            <p
                className={`inline-flex px-3 text-xs font-semibold leading-8 text-black-800 rounded-full ${statusColorClass}`}>
                {status}
            </p>
        </div>
    );
};

function createData(data) {
    const { id, name, subject } = data;
    return { id, name, subject, data };
}

const EnquiryPaginatedTable = ({ handleActionButtonClick, isEnquiryListLoading, enquiriesQuery, setPage }) => {
    const { t } = useTranslation();
     

    // Columns of dataGrid (If you are passing "renderCell" then no need to pass that data in "row" eg. action and status columns.)
    const columnss = [
        {
            field: 'id',
            headerName: t('customerSupport.id'),
            minWidth: 70,
            editable: false,
        },
        {
            field: 'name',
            headerName: t('customerSupport.name'),
            minWidth: 190,
            editable: false,
        },
        {
            field: 'subject',
            headerName: t('customerSupport.subject'),
            minWidth: 280,
            editable: false,
        },
        {
            field: 'status',
            headerName: t('customerSupport.status'),
            minWidth: 130,
            editable: false,
            renderCell: (row) => (
                <div>
                    <StatusCell status={row.row.data.status.description} statusId={row.row.data.status.value} />
                </div>
            ),
        },
        {
            field: 'action',
            headerName: t('customerSupport.action'),
            minWidth: 130,
            editable: false,
            renderCell: (row) => {
                return (
                    <div>
                        <IconButton
                            onClick={() => {
                                handleActionButtonClick(row.row.data);
                            }}
                            size="large">
                            <SpeakerNotesIcon />
                        </IconButton>
                    </div>
                );
            },
        },
    ];

    // Preparing ROWS
    const enquiriesList = enquiriesQuery.data?.data;

    const rows = enquiriesList
        ? enquiriesList.map((data) => {
              return createData(data);
          })
        : [];

    return (
        <ResourcesList
            headerProps={{
                title: t('customerSupport.enquires'),
                EndSide: (
                    <div>
                        <IconButton
                            onClick={() => {
                                enquiriesQuery.refetch();
                            }}
                            size="large">
                            <RefreshIcon />
                        </IconButton>
                    </div>
                ),
            }}
            Section={
                <div className="mt-8">
                    <div>
                        <Paper>
                            <DataGrid
                                pagination
                                rowCount={enquiriesQuery.data?.meta.total}
                                paginationMode="server"
                                onPageChange={(newPage) => setPage(newPage)}
                                rows={rows}
                                columns={columnss}
                                pageSize={enquiriesQuery.data?.meta.per_page}
                            />
                        </Paper>
                    </div>
                </div>
            }
        />
    );
};

export default EnquiryPaginatedTable;
