import React, { useEffect } from 'react';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { handleError } from '@aseel/http';
import { Yup } from '@aseel/common-utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent } from '@mui/material';
import CountryCodes from 'Constants/CountryCodes.json';
import { AUTHORIZED_ENTITY_USERS } from 'Constants/QueriesKeys';
import { Btn, InputController, SelectController, setProgress, FormSkeleton } from '@aseel/common-ui';


const INVITE_UPDATE_BTN = 'INVITE_UPDATE_BTN';

const schema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    email: Yup.string().required(),
    phone_country_code: Yup.string().required().nullable(),
    phone: Yup.string().required(),
});

function CreateEditUserDialog({ isOpen, isEdit, usersQuery, setIsOpen, selectedUser, authorizedEntityId }) {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            phone_country_code: 'SA',
        },
    });

    const userInfoQuery = useQuery(
        [AUTHORIZED_ENTITY_USERS, { id: authorizedEntityId, userId: selectedUser?.id }],
        () =>
            http
                .get(`/authorized-entities/${authorizedEntityId}/users/${selectedUser?.id}`)
                .then((response) => response.data.data),
        {
            onSuccess: (data) => {
                reset({ ...data });
            },
            enabled: Boolean(selectedUser?.id),
        },
    );

    const handleCloseUsersDialog = () => {
        setIsOpen(false);
    };

    const handleFormSubmission = async (values) => {
        setProgress(INVITE_UPDATE_BTN, true);

        try {
            await http.request({
                method: isEdit ? 'PUT' : 'POST',
                url: isEdit
                    ? `/authorized-entities/${authorizedEntityId}/users/${selectedUser?.id}`
                    : `/authorized-entities/${authorizedEntityId}/users`,
                data: values,
            });
            usersQuery.refetch();
            setIsOpen(false);
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(INVITE_UPDATE_BTN, false);
    };

    useEffect(() => {
        if (isOpen && isEdit === false) {
            reset({
                phone_country_code: 'SA',
            });
        }

        if (isOpen && isEdit) {
            userInfoQuery.refetch();
        }
    }, [isEdit, isOpen]);

    return (
        <Dialog open={isOpen} onClose={handleCloseUsersDialog} fullWidth>
            <DialogContent>
                {userInfoQuery.isLoading ? (
                    <FormSkeleton inputsCount={4} />
                ) : (
                    <form onSubmit={handleSubmit(handleFormSubmission)}>
                        <div className="mb-3 font-bold text-2xl">
                            {isEdit
                                ? t('authEntities.users.edit', {
                                      firstLastName: `${selectedUser?.full_name}`,
                                  })
                                : t('authEntities.users.inviteUser')}
                        </div>

                        <div className="space-y-4">
                            <div>
                                <InputController
                                    control={control}
                                    name="first_name"
                                    label={t('authEntities.users.firstName')}
                                />
                            </div>
                            <div>
                                <InputController
                                    control={control}
                                    name="last_name"
                                    label={t('authEntities.users.lastName')}
                                />
                            </div>
                            <div>
                                <InputController control={control} name="email" label={t('authEntities.users.email')} />
                            </div>
                            <div className="grid gap-4 grid-cols-6">
                                <SelectController
                                    control={control}
                                    name="phone_country_code"
                                    label={t('authEntities.users.countryCode')}
                                    options={CountryCodes}
                                    getOptionValue={(option) => option.Iso2}
                                    getOptionLabel={(option) => option.Dial}
                                    className="col-span-2"
                                />
                                <InputController
                                    name="phone"
                                    control={control}
                                    label={t('authEntities.users.phone')}
                                    className="col-span-4"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-3">
                            <Btn type="submit" name={INVITE_UPDATE_BTN} fullWidth={false}>
                                {isEdit ? t('authEntities.users.update') : t('authEntities.users.invite')}
                            </Btn>
                        </div>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default CreateEditUserDialog;
