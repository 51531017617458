import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Paper, DialogContent, IconButton, Typography, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import WarningIcon from '@mui/icons-material/Warning';
import { useQuery } from 'react-query';
import { INVESTOR_AML } from 'Constants/QueriesKeys';
import { http } from 'Utils/Http/Http';
import { DataGrid, ResourceListSkeleton } from '@aseel/common-ui';

const amlStatus = {
    passed: 1,
    found: 2,
    notChecked: 3,
};

const StatusCell = ({ status }) => {
    if (status === amlStatus.passed) {
        return <CheckIcon size="small" style={{ color: 'green' }} />;
    } else if (status === amlStatus.found) {
        return <WarningIcon size="small" style={{ color: 'red' }} />;
    } else if (status === amlStatus.notChecked) {
        return <ClearIcon size="small" />;
    }
};

// Component goes here
const AMLModal = ({ isDrawarOpen, setIsDrawarOpen, startDate, endDate, setStartDate, setEndDate, investor }) => {
    const [page, setPage] = useState(1);
    const { t } = useTranslation();

    const closeModal = () => {
        setIsDrawarOpen(false);
    };

    const amlQuery = useQuery(
        [INVESTOR_AML, { id: investor?.id }],
        () =>
            http
                .get(`/aml/users/${investor?.id}/list`, {
                    params: { page, start: startDate, end: endDate },
                })
                .then(({ data }) => data),
        {
            enabled: Boolean(investor?.id),
            retry: false,
        },
    );

    useEffect(() => {
        if (isDrawarOpen) {
            amlQuery.refetch();
        }
    }, [isDrawarOpen]);

    const columns = [
        {
            field: 'check_date',
            headerName: t('amlHistory.checkDate'),
            minWidth: 150,
            editable: false,
        },
        {
            field: 'UN',
            headerName: t('amlHistory.un'),
            minWidth: 100,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <StatusCell
                        status={row.keyedByListChecks[1]?.status}
                        found={row.keyedByListChecks[1]?.details.found}
                    />
                </div>
            ),
        },
        {
            field: 'US',
            headerName: t('amlHistory.us'),
            minWidth: 100,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <StatusCell
                        status={row.keyedByListChecks[2]?.status}
                        found={row.keyedByListChecks[2]?.details.found}
                    />
                </div>
            ),
        },
        {
            field: 'EU',
            headerName: t('amlHistory.eu'),
            minWidth: 100,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <StatusCell
                        status={row.keyedByListChecks[3]?.status}
                        found={row.keyedByListChecks[3]?.details.found}
                    />
                </div>
            ),
        },
        {
            field: 'MOI',
            headerName: t('amlHistory.moi'),
            minWidth: 100,
            editable: false,
            renderCell: ({ row }) => (
                <div>
                    <StatusCell
                        status={row.keyedByListChecks[4]?.status}
                        found={row.keyedByListChecks[4]?.details.found}
                    />
                </div>
            ),
        },
    ];

    const data = useMemo(() => {
        if (amlQuery.data === undefined) {
            return [];
        }

        return amlQuery.data.data.map((aml) => {
            let keyedByListChecks = {};
            aml.checks.forEach((prop) => (keyedByListChecks[prop.list] = prop));
            return {
                ...aml,
                keyedByListChecks,
            };
        });
    }, [amlQuery.data]);

    return (
        <Dialog fullWidth={true} maxWidth="md" open={isDrawarOpen} onClose={closeModal}>
            <DialogContent>
                {amlQuery.isLoading ? (
                    <ResourceListSkeleton />
                ) : (
                    <>
                        <div className="flex justify-between">
                            <div>
                                <div>
                                    <Typography variant="h6" color="textSecondary">
                                        {t('investor.amlHistory')}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('investor.id')}: {investor?.id}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('investor.name')}: {investor?.name}
                                    </Typography>
                                </div>
                            </div>
                            <div>
                                <IconButton
                                    onClick={() => {
                                        closeModal(false);
                                    }}
                                    size="large">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <Divider
                            variant="fullWidth"
                            component="hr"
                            orientation="horizontal"
                            style={{ width: '100%', margin: '20px 0px 20px' }}
                        />
                        <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
                            <div className="flex justify-between text-center">
                                <div>
                                    <CheckIcon size="small" style={{ color: 'green' }} />
                                    <Typography variant="body2" color="textSecondary">
                                        {t('investor.aml.passed')}
                                    </Typography>
                                </div>
                                <div>
                                    <ClearIcon size="small" />
                                    <Typography variant="body2" color="textSecondary">
                                        {t('investor.aml.notChecked')}
                                    </Typography>
                                </div>
                                <div>
                                    <WarningIcon size="small" style={{ color: 'red' }} />
                                    <Typography variant="body2" color="textSecondary">
                                        {t('investor.aml.found')}
                                    </Typography>
                                </div>
                            </div>
                            <div className=" space-y-5">
                                <TextField
                                    fullWidth
                                    id="date"
                                    label="Start Date"
                                    type="date"
                                    defaultValue={startDate}
                                    onChange={(e) => {
                                        setStartDate(e.target.value);
                                        amlQuery.refetch();
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="date"
                                    label="End Date"
                                    type="date"
                                    defaultValue={endDate}
                                    onChange={(e) => {
                                        setEndDate(e.target.value);
                                        amlQuery.refetch();
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mt-8">
                            <div>
                                <Paper>
                                    <DataGrid
                                        pagination
                                        rowCount={amlQuery.data?.meta.total}
                                        paginationMode="server"
                                        onPageChange={(page) => setPage(page + 1)}
                                        loading={amlQuery.isFetching}
                                        rows={data}
                                        columns={columns}
                                        pageSize={amlQuery.data?.meta.per_page}
                                        page={page - 1}
                                    />
                                </Paper>
                            </div>
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AMLModal;
