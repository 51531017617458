import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { ResourcesList, TableSkeleton } from '@aseel/common-ui';
import { Card } from '@mui/material';
import { BANK_ACCOUNTS } from 'Constants/QueriesKeys';
import BankAccountsTable from './Components/BankAccountsTable';
import { i18n } from '@aseel/common-utils';
import RoundedTabs from '@aseel/common-ui/src/Components/Tabs/RoundedTabs';
import BankAccountInfoDialog from 'Components/BankAccountInfoDialog';

export const tabsValues = {
    all: 'all',
    investor: 'inv',
    authorizedEntity: 'ap',
};

export const tabs = () => [
    {
        value: tabsValues.all,
        label: i18n.t('bankAcc.all'),
    },
    {
        value: tabsValues.investor,
        label: i18n.t('bankAcc.investors'),
    },
    {
        value: tabsValues.authorizedEntity,
        label: i18n.t('bankAcc.authorizedEntities'),
    },
];

export default function BankAccountsPage() {
    const { t } = useTranslation();
    const [page, setPage] = useState(0);
    const [currentTab, setCurrentTab] = useState(tabsValues.all);
    const [bankInfo, setBankInfo] = useState({
        show: false,
        info: null,
    });

    const bankAccountsQuery = useQuery([BANK_ACCOUNTS, { currentTab, page }], () =>
        http
            .get('/bank-accounts', {
                params: {
                    accountable: currentTab === 'all' ? null : currentTab,
                    page: page + 1,
                },
            })
            .then((response) => response.data),
    );

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const onShowBankInfo = (row) => {
        setBankInfo({
            show: true,
            info: row,
        });
    };

    const handleCloseBankInfo = () => {
        setBankInfo({
            ...bankInfo,
            show: false,
        });
        bankAccountsQuery.refetch();
    };

    return (
        <>
            <BankAccountInfoDialog
                isOpen={bankInfo.show}
                onClose={handleCloseBankInfo}
                bankInfo={bankInfo}
                bankAccountsQuery={bankAccountsQuery.data}
                setBankInfo={setBankInfo}
                refetchBankAccounts={bankAccountsQuery.refetch}
            />

            <ResourcesList
                headerProps={{
                    title: t('bankAcc.bankAccounts'),
                }}
                AfterHeader={
                    <div>
                        <div className="px-2.5 md:px-0">
                            <RoundedTabs
                                tabs={tabs()}
                                value={currentTab}
                                onChange={handleTabChange}
                                onClick={() => bankAccountsQuery.refetch()}
                            />
                        </div>
                        <div>
                            {bankAccountsQuery.isLoading ? (
                                <Card>
                                    <TableSkeleton />
                                </Card>
                            ) : (
                                <BankAccountsTable
                                    rows={bankAccountsQuery.data?.data}
                                    setBankInfo={setBankInfo}
                                    currentTab={currentTab}
                                    page={page}
                                    setPage={setPage}
                                    query={bankAccountsQuery}
                                    onShowBankInfo={onShowBankInfo}
                                />
                            )}
                        </div>
                    </div>
                }
            />
        </>
    );
}
