import React from 'react';
import { useTranslation } from 'react-i18next';
import Logs from 'Components/Logs/Log';
import { SYSTEM_LOGS } from 'Constants/QueriesKeys';

const SystemLogsPage = () => {
    const { t } = useTranslation();

    return <Logs title={t('logs.systemLogs')} url={'/logs'} queryKey={SYSTEM_LOGS} />;
};

export default SystemLogsPage;
