import React from 'react';
import { Typography } from '@mui/material';
import { Skeleton } from '@mui/material';

function UserInfoDialogSkeleton() {
    return (
        <div>
            <div className="mb-3">
                <Typography variant="h5">
                    <Skeleton variant="h2" className="w-1/3" />
                </Typography>
            </div>
            <div className="grid grid-cols-2 gap-4 pb-3">
                <div>
                    <Typography variant="body2">
                        <Skeleton className="w-1/2" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/4" />
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2">
                        <Skeleton className="w-1/2" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/4" />
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2">
                        <Skeleton className="w-1/2" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/4" />
                    </Typography>
                </div>
                <div>
                    <Typography variant="body2">
                        <Skeleton className="w-1/2" />
                    </Typography>
                    <Typography>
                        <Skeleton className="w-1/4" />
                    </Typography>
                </div>
            </div>
        </div>
    );
}

export default UserInfoDialogSkeleton;
