import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function InvestorProfileInformation({ investorInfo, className }) {
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.investmentObjectives')}
                </Typography>
                <Typography variant="body1">{investorInfo.profile.investment_objectives.join(' - ')}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.currentInvestements')}
                </Typography>
                <Typography variant="body1">{investorInfo.profile.current_investements.join(' - ')}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.investmentXp')}
                </Typography>
                <Typography variant="body1">{investorInfo.profile.investment_xp}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.entityType')}
                </Typography>
                <Typography variant="body1">{investorInfo.profile.entity_type}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.yearsOfInvInSecurities')}
                </Typography>
                <Typography variant="body1">{investorInfo.profile.years_of_inv_in_securities}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.xpInToFinSector')}
                </Typography>
                <Typography variant="body1">
                    {investorInfo.profile.any_xp_in_fin_sector ? t('common.yes') : t('common.no')}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.xpRelatedToFinSector')}
                </Typography>
                <Typography variant="body1">
                    {investorInfo.profile.any_xp_related_to_fin_sector ? t('common.yes') : t('common.no')}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.haveYouInvestedInEstates')}
                </Typography>
                <Typography variant="body1">
                    {investorInfo.profile.have_you_invested_in_real_estates ? t('common.yes') : t('common.no')}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.isOnBoard')}
                </Typography>
                <Typography variant="body1">
                    {investorInfo.profile.is_on_board ? t('common.yes') : t('common.no')}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.annualIncome')}
                </Typography>
                <Typography variant="body1">SAR {investorInfo.profile.annual_income}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.netWorth')}
                </Typography>
                <Typography variant="body1">{investorInfo.profile.net_worth}</Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.expectedInvPerOpp')}
                </Typography>
                <Typography variant="body1">
                    SAR {investorInfo.profile.expected_invest_amount_per_opportunity}
                </Typography>
            </div>
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    {t('investor.expectedAnnualInv')}
                </Typography>
                <Typography variant="body1">SAR {investorInfo.profile.expected_annual_invest}</Typography>
            </div>
        </div>
    );
}
