import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { INVESTOR_BALANCE, INVESTOR_TRANSACTIONS } from 'Constants/QueriesKeys';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import TableSkeleton from 'Skeletons/TableSkeleton';
import { DataGrid } from '@aseel/common-ui';

// Component goes here
const BalanceModal = ({ isDrawarOpen, setIsDrawarOpen, investor }) => {
    const [page, setPage] = useState(1);

    const balanceQuery = useQuery(
        [INVESTOR_BALANCE, { id: investor?.id }],
        () => http.get(`/investors/${investor?.id}/balance-inquiry`).then(({ data }) => data),
        {
            enabled: Boolean(investor?.id),
            retry: false,
        },
    );

    const transactionQuery = useQuery(
        [INVESTOR_TRANSACTIONS, { id: investor?.id, page }],
        () => http.get(`/investors/${investor?.id}/transactions`, { params: { page } }).then(({ data }) => data),
        {
            enabled: Boolean(investor?.id),
        },
    );

    const { t } = useTranslation();
    const closeModal = () => {
        setIsDrawarOpen(false);
    };

    useEffect(() => {
        if (isDrawarOpen) {
            setPage(1);
        }
    }, [isDrawarOpen]);

    const columns = [
        {
            field: 'created_at',
            headerName: t('investor.transaction.createdAt'),
            width: 150,
            editable: false,
        },
        {
            field: 'description',
            headerName: t('investor.transaction.description'),
            minWidth: 300,
            flex: 1,
            editable: false,
        },
        {
            field: 'credit',
            headerName: t('investor.transaction.credit'),
            width: 120,
            editable: false,
        },
        {
            field: 'debit',
            headerName: t('investor.transaction.debit'),
            width: 120,
            editable: false,
        },
    ];

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={isDrawarOpen}
            aria-labelledby="simple-dialog-title"
            onClose={closeModal}>
            <DialogContent>
                <div className="flex justify-between items-center">
                    <div>
                        <Typography variant="h6">
                            {t('investor.investorBalanceTitle', { name: investor?.first_name, id: investor?.id })}
                        </Typography>
                    </div>
                    <div>
                        <IconButton style={{ float: 'right' }} onClick={closeModal} size="large">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>

                <Typography variant="subtitle1" component="h1" color="textSecondary">
                    {t('investor.balance')}
                </Typography>
                <Typography variant="h6" component="p" className="mb-4">
                    SAR {balanceQuery.data?.balance === undefined ? '---' : balanceQuery.data?.balance}
                </Typography>

                <div>
                    <Typography variant="subtitle1" component="h1" color="textSecondary">
                        {t('investor.transactions')}
                    </Typography>
                    <div>
                        {transactionQuery.isLoading || transactionQuery.data === undefined ? (
                            <TableSkeleton />
                        ) : (
                            <DataGrid
                                pagination
                                rowCount={transactionQuery.data?.meta.total}
                                paginationMode="server"
                                onPageChange={(page) => setPage(page + 1)}
                                loading={transactionQuery.isLoading}
                                rows={transactionQuery.data.data}
                                columns={columns}
                                pageSize={transactionQuery.data?.meta.per_page}
                                page={page - 1}
                            />
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default BalanceModal;
