import React from 'react';
import { http } from 'Utils/Http/Http';
import { useQuery } from 'react-query';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { handleError } from '@aseel/http';
import { i18n } from '@aseel/common-utils';
import { useTranslation } from 'react-i18next';
import { approvalStatus } from '@aseel/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogContent } from '@mui/material';
import { IconButton, Typography } from '@mui/material';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import { BANK_ACCOUNTS } from 'Constants/QueriesKeys';
import { SelectController, InputController, Btn, setProgress } from '@aseel/common-ui';
import BankAccountsDialogSkeleton from 'Pages/BankAccounts/Skeletons/BankAccountsDialogSkeleton';

const STATUS_BTN = 'STATUS_BTN';

const statusDescriptions = () => [
    {
        value: approvalStatus.pendingReview,
        label: i18n.t('common.pendingReview'),
    },
    {
        value: approvalStatus.inReview,
        label: i18n.t('common.inReview'),
    },
    {
        value: approvalStatus.approved,
        label: i18n.t('common.approved'),
    },
    {
        value: approvalStatus.rejected,
        label: i18n.t('common.rejected'),
    },
];

const schema = Yup.object().shape({
    status: Yup.number().required().nullable(),
});

export default function BankAccountInfoDialog({ isOpen, onClose, bankInfo, setBankInfo, refetchBankAccounts }) {
    const { t } = useTranslation();
    const { control, handleSubmit, reset, setError, watch } = useForm({
        resolver: yupResolver(schema),
    });
    const status = watch('status');
    const shouldShowComment = status === approvalStatus.rejected;

    const bankAccountInfo = useQuery(
        [BANK_ACCOUNTS, { id: bankInfo.info?.id }],
        () => http.get(`/bank-accounts/${bankInfo.info.id}`).then((response) => response.data.data),
        {
            onSuccess: (data) => {
                reset({ ...data });
            },
        },
    );

    const handleSubmitStatus = async (values) => {
        setProgress(STATUS_BTN, true);
        try {
            await http.put(`/bank-accounts/${bankInfo.info?.id}/status`, values);
            refetchBankAccounts();
            setBankInfo({
                show: false,
            });
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(STATUS_BTN, false);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogContent className="space-y-2.5">
                {bankAccountInfo.isLoading ? (
                    <BankAccountsDialogSkeleton />
                ) : (
                    <>
                        <div className="grid lg:grid-cols-2 gap-4">
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('bankAcc.name')}
                                </Typography>
                                <Typography>{bankInfo.info?.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('bankAcc.bank')}
                                </Typography>
                                <Typography>{bankInfo.info?.bank}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('bankAcc.iban')}
                                </Typography>
                                <Typography>{bankInfo.info?.iban}</Typography>
                            </div>
                            <div className="w-1/2">
                                <Typography variant="body2" color="textSecondary">
                                    {t('bankAcc.reviewerName')}
                                </Typography>
                                <Typography>{bankInfo.info?.reviewer_name || '-'}</Typography>
                            </div>
                        </div>
                        <div>
                            {bankAccountInfo.data?.attachments?.length ? (
                                <div>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('bankAcc.attachments')}
                                    </Typography>
                                    <Typography>
                                        {bankAccountInfo.data.attachments.map((item) => (
                                            <div className="flex justify-between items-center">
                                                <Typography>{item.name}</Typography>
                                                <a href={item.url} target="_blank">
                                                    <IconButton size="large">
                                                        <GetAppRoundedIcon className="text-gray-500" />
                                                    </IconButton>
                                                </a>
                                            </div>
                                        ))}
                                    </Typography>
                                </div>
                            ) : null}
                        </div>
                        <div className="pt-5">
                            <div className="flex divide-y-4 divide-black">
                                <form onSubmit={handleSubmit(handleSubmitStatus)} className="w-full space-y-2.5">
                                    <SelectController
                                        name="status"
                                        control={control}
                                        label={t('bankAcc.status')}
                                        options={statusDescriptions()}
                                        menuPortalTarget={document.body}
                                    />
                                    {shouldShowComment ? (
                                        <InputController
                                            name="comment"
                                            control={control}
                                            label={t('bankAcc.comment')}
                                            multiline
                                            rows={7}
                                        />
                                    ) : null}
                                    <div className="flex justify-end">
                                        <Btn type="submit" name={STATUS_BTN} fullWidth={false}>
                                            {t('common.update')}
                                        </Btn>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
}
