import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { useTranslation } from 'react-i18next';
import { Card, IconButton } from '@mui/material';
import { approvalStatusModalTypes } from '@aseel/common-components';
import { Link, useRouteMatch } from 'react-router-dom';
import { AUTHORIZED_ENTITIES } from 'Constants/QueriesKeys';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { ApprovalStatusChip } from '@aseel/common-components';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import CreateEditEntityDialog from './Components/CreateEditEntityDialog';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { ResourceListSkeleton, DataGrid, BtnBase, ResourcesList } from '@aseel/common-ui';

function AuthorizedEntitiesPage() {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const [page, setPage] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const authorizedEntitiesQuery = useQuery([AUTHORIZED_ENTITIES, { page }], () =>
        http
            .get('/authorized-entities', {
                params: {
                    page: page + 1,
                },
            })
            .then((response) => response.data),
    );

    const handleCloseCreateEntity = () => {
        setIsDialogOpen(false);
    };

    const handleEditEntityInfo = (row) => {
        setIsEdit(true);
        setSelectedEntity(row);
        setIsDialogOpen(true);
    };

    const handleCreateEntity = () => {
        setIsEdit(false);
        setIsDialogOpen(true);
    };

    const authEntitiesColumns = [
        {
            field: 'id',
            headerName: t('authEntities.id'),
        },
        {
            field: 'name',
            headerName: t('authEntities.name'),
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('authEntities.status'),
            flex: 1,
            renderCell: (row) => (
                <ApprovalStatusChip label={row.row.status.description} status={row.row.status.value} modalType={approvalStatusModalTypes.general} />
            ),
        },
        {
            field: 'reviewer_name',
            headerName: t('authEntities.reviewerName'),
            flex: 1,
            renderCell: (row) => <div className="text-center">{row.row.reviewer_name?.name ?? '---'}</div>,
        },
        {
            field: 'actions',
            headerName: t('authEntities.actions'),
            minWidth: 180,
            renderCell: (row) => (
                <div>
                    <IconButton component={Link} to={`${path}/${row.row.id}`} size="large">
                        <VisibilityRoundedIcon />
                    </IconButton>
                    <IconButton component={Link} to={`${path}/${row.row.id}/users`} size="large">
                        <GroupRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditEntityInfo(row.row)} size="large">
                        <EditRoundedIcon />
                    </IconButton>
                </div>
            ),
        },
    ];

    if (authorizedEntitiesQuery.isLoading) {
        return <ResourceListSkeleton hasEndSide />;
    }

    return (
        <>
            <CreateEditEntityDialog
                isOpen={isDialogOpen}
                onClose={handleCloseCreateEntity}
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
                isEdit={isEdit}
                selectedEntity={selectedEntity}
                authorizedEntitiesQuery={authorizedEntitiesQuery}
            />
            <ResourcesList
                headerProps={{
                    title: t('authEntities.authorizedEntities'),
                    EndSide: (
                        <BtnBase fullWidth={false} onClick={handleCreateEntity}>
                            {t('common.create')}
                        </BtnBase>
                    ),
                }}
                Section={
                    <div className="mt-3.5">
                        <Card>
                            <DataGrid
                                columns={authEntitiesColumns}
                                rows={authorizedEntitiesQuery.data?.data}
                                page={page}
                                pageSize={authorizedEntitiesQuery.data?.meta.per_page}
                                rowCount={authorizedEntitiesQuery.data?.meta.total}
                                onPageChange={(newPage) => setPage(newPage)}
                                paginationMode="server"
                                pagination
                            />
                        </Card>
                    </div>
                }
            />
        </>
    );
}

export default AuthorizedEntitiesPage;
