import React from 'react';
import i18n from 'Utils/I18n';
import { useQuery } from 'react-query';
import { http } from 'Utils/Http/Http';
import { toast } from 'react-toastify';
import Link from '@mui/material/Link';
import { handleError } from '@aseel/http';
import { Yup } from '@aseel/common-utils';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateToast } from '@aseel/common-utils';
import { approvalStatus } from '@aseel/constants';
import { NavLink, useParams } from 'react-router-dom';
import { approvalStatusModalTypes } from '@aseel/common-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { AUTHORIZED_ENTITIES } from 'Constants/QueriesKeys';
import { ApprovalStatusChip } from '@aseel/common-components';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import AuthorizedEntityInfoSkeleton from './Skeletons/AuthorizedEntityInfoSkeleton';
import { Breadcrumbs, Card, CardContent, Divider, Typography } from '@mui/material';
import { InputController, SelectController, Btn, setProgress } from '@aseel/common-ui';

const STATUS_BTN = 'STATUS_BTN';

const statusDescriptions = () => [
    {
        value: approvalStatus.pendingReview,
        label: i18n.t('common.pendingReview'),
    },
    {
        value: approvalStatus.inReview,
        label: i18n.t('common.inReview'),
    },
    {
        value: approvalStatus.approved,
        label: i18n.t('common.approved'),
    },
    {
        value: approvalStatus.rejected,
        label: i18n.t('common.rejected'),
    },
];

const schema = Yup.object().shape({
    status: Yup.number().required().nullable(),
});

function AuthorizedEntityPage() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { control, handleSubmit, setError, watch } = useForm({
        resolver: yupResolver(schema),
    });
    const authorizedEntityQuery = useQuery([AUTHORIZED_ENTITIES, { id }], () =>
        http.get(`/authorized-entities/${id}`).then((response) => response.data.data),
    );

    const status = watch('status');

    const shouldShowComment = status === approvalStatus.rejected;

    if (authorizedEntityQuery.isLoading) {
        return <AuthorizedEntityInfoSkeleton />;
    }

    const handleSubmitStatus = async (values) => {
        setProgress(STATUS_BTN, true);
        const toastId = toast.loading(t('messages.updatingStatus'));
        try {
            await http.put(`/authorized-entities/${id}/status`, values);
            authorizedEntityQuery.refetch();
            updateToast(toastId, { type: 'success', render: t('messages.statusUpdatedSuccessfully') });
        } catch (error) {
            handleError(error, { setError });
        }
        setProgress(STATUS_BTN, false);
    };

    return (
        <div className="px-3.5">
            <Breadcrumbs className="mb-4">
                <Link component={NavLink} to="/app/authorized-entities">
                    {t('authEntities.authorizedEntities')}
                </Link>
                <Typography color="textPrimary">
                    #{id} {authorizedEntityQuery.data?.name}
                </Typography>
            </Breadcrumbs>
            <div className="space-y-5">
                <Card className="mt-3">
                    <div className="px-4 py-5">
                        <Typography variant="h5">{t('authEntities.entityInfo')}</Typography>
                    </div>
                    <Divider />
                    <CardContent>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.name')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data?.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.crNumber')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data?.cr_number}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.status')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data?.status.description}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.reviewerName')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data.reviewer_name?.name ?? '---'}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.description')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data?.description ?? '---'} </Typography>
                            </div>
                        </div>

                        <div className="py-4 space-y-4">
                            {authorizedEntityQuery.data.cma_lisence.map((item) => (
                                <div>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('authEntities.cmaLisence')}
                                    </Typography>
                                    <div className="flex items-center justify-between">
                                        <Typography>{item.name}</Typography>
                                        <a href={item.url} target="_blank">
                                            <GetAppRoundedIcon className="text-gray-600" />
                                        </a>
                                    </div>
                                </div>
                            ))}

                            {authorizedEntityQuery.data.cr.map((item) => (
                                <div>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('authEntities.cr')}
                                    </Typography>
                                    <div className="flex items-center justify-between">
                                        <Typography>{item.name}</Typography>
                                        <a href={item.url} target="_blank">
                                            <GetAppRoundedIcon className="text-gray-600" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>
                <Card>
                    <div className="px-4 py-5">
                        <Typography variant="h5">{t('authEntities.creatorInfo')}</Typography>
                    </div>
                    <Divider />
                    <CardContent>
                        <div className="grid md:grid-cols-3 gap-5">
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.name')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data?.creator.name}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.role')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data?.creator.role}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" color="textSecondary">
                                    {t('authEntities.phoneNumber')}
                                </Typography>
                                <Typography>{authorizedEntityQuery.data?.creator.phone_number}</Typography>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <form onSubmit={handleSubmit(handleSubmitStatus)} className="w-full space-y-2.5">
                    <Card>
                        <div className="px-4 py-5">
                            <Typography variant="h5">{t('authEntities.updateStatus')}</Typography>
                        </div>
                        <Divider />
                        <div className="space-y-1.5 pl-4 pt-6">
                            <Typography variant="body2" color="textSecondary">
                                {t('investor.currentStatus')}
                            </Typography>
                            <ApprovalStatusChip
                                label={authorizedEntityQuery.data?.status.description}
                                status={authorizedEntityQuery.data?.status.value}
                                modalType={approvalStatusModalTypes.general}
                            />
                        </div>
                        <CardContent className="py-5 space-y-3.5">
                            <SelectController
                                name="status"
                                control={control}
                                label={t('bankAcc.status')}
                                options={statusDescriptions()}
                                menuPortalTarget={document.body}
                            />
                            {shouldShowComment ? (
                                <InputController
                                    name="comment"
                                    control={control}
                                    label={t('bankAcc.comment')}
                                    multiline
                                    rows={7}
                                />
                            ) : null}
                            <div className="flex justify-end">
                                <Btn type="submit" name={STATUS_BTN} fullWidth={false}>
                                    {t('common.update')}
                                </Btn>
                            </div>
                        </CardContent>
                    </Card>
                </form>
            </div>
        </div>
    );
}

export default AuthorizedEntityPage;
