import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function InvestorAddressInformationFromAbsher({ addressInfo, className, infoContainerClassName }) {
    const { t } = useTranslation();
    return (
        <div className={className}>
            <Typography variant="h6" className="mb-1.5 font-semibold">
                {t('investor.addressTitle', { count: addressInfo?.length })}
            </Typography>
            {addressInfo?.map((item, index) => (
                <div>
                    {addressInfo.length === 1 ? null : (
                        <div>
                            <Typography variant="subtitle2" className="mb-1.5 font-medium">
                                {t('investor.address', { addressIndex: index + 1 })}
                            </Typography>
                        </div>
                    )}
                    <div className={infoContainerClassName}>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.unitNumber')}
                            </Typography>
                            <Typography variant="body1">{item.unit_number}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.buildingNumber')}
                            </Typography>
                            <Typography variant="body1">{item.building_number}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.city')}
                            </Typography>
                            <Typography variant="body1">{item.city}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.district')}
                            </Typography>
                            <Typography variant="body1">{item.district}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.street')}
                            </Typography>
                            <Typography variant="body1">{item.street_name}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.postalCode')}
                            </Typography>
                            <Typography variant="body1">{item.post_code}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.additionalNumber')}
                            </Typography>
                            <Typography variant="body1">{item.additional_number}</Typography>
                        </div>
                        <div>
                            <Typography variant="subtitle2" color="textSecondary">
                                {t('investor.location')}
                            </Typography>
                            <Typography variant="body1">{item.location}</Typography>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}
