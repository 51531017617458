import React, { useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { http } from 'Utils/Http/Http';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Yup } from '@aseel/common-utils';
import { handleError } from '@aseel/http';
import { DatePickerController, InputController, Btn, setProgress, FormSkeleton } from '@aseel/common-ui';
import { useQuery } from 'react-query';
import { FUND_VALUATIONS } from 'Constants/QueriesKeys';

const CREATE_EDIT_BTN = 'CREATE_EDIT_BTN';

const schema = Yup.object().shape({
    date: Yup.mixed().required(),
    value: Yup.string().required(),
});

function CreateEditValuationsDialog({ isEdit, isOpen, onClose, fundId, data, valuationsQuery, getValuationRequest }) {
    const { t } = useTranslation();
    const valuationQuery = useQuery(
        [FUND_VALUATIONS, { fundId, id: data?.id }],
        () => getValuationRequest({ valuationId: data.id }),
        {
            enabled: Boolean(data?.id) && isOpen,
            onSuccess: (queryData) => {
                reset({ date: queryData.date, value: queryData.value });
            },
        },
    );

    const { control, handleSubmit, reset, setError } = useForm({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        if (isOpen && isEdit === false) {
            reset({});
        }
    }, [isOpen, isEdit]);

    const handleFormSubmission = async (values) => {
        setProgress(CREATE_EDIT_BTN, true);

        if (typeof values.date === 'object') {
            values.date = values.date.format('YYYY-MM-DD');
        }

        try {
            await http.request({
                method: isEdit ? 'PUT' : 'POST',
                url: isEdit ? `/listings/${fundId}/valuations/${data?.id}` : `/listings/${fundId}/valuations`,
                data: values,
            });
            valuationsQuery.refetch();
            onClose();
        } catch (error) {
            handleError(error, { setError });
        }

        setProgress(CREATE_EDIT_BTN, false);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth>
            <DialogContent>
                {valuationQuery.isLoading ? (
                    <FormSkeleton inputsCount={2} />
                ) : (
                    <form onSubmit={handleSubmit(handleFormSubmission)}>
                        <div className="mb-5 font-bold text-2xl">
                            {isEdit ? t('funds.valuations.edit') : t('funds.valuations.createValuation')}
                        </div>

                        <div className="space-y-4">
                            <DatePickerController
                                control={control}
                                name="date"
                                label={t('funds.valuations.date')}
                                className="w-full"
                            />
                            <InputController control={control} name="value" label={t('funds.valuations.value')} />
                        </div>
                        <div className="flex justify-end mt-5">
                            <Btn type="submit" name={CREATE_EDIT_BTN} fullWidth={false}>
                                {isEdit ? t('common.update') : t('common.create')}
                            </Btn>
                        </div>
                    </form>
                )}
            </DialogContent>
        </Dialog>
    );
}

export default CreateEditValuationsDialog;
