import React from 'react';
import Conversion from './Components/Conversion/Conversion';
import Funds from './Components/Fund/Funds';
import Overview from './Components/Overview/Overview';

const DashboardPage = () => {
    return (
        <div className="space-y-7 px-4 sm:px-0">
            <Overview />
            {/* <Conversion /> */}
            <Funds />
        </div>
    );
};

export default DashboardPage;
